// src/components/tinbit/data/data.js

// Shuffling cards when loading them into TINBIT
const shuffleArray = (array) => {
  const shuffledArray = array.slice(); // Create a copy of the array
  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }
  return shuffledArray;
};

export const companies = shuffleArray([
  {
    id: 1,
    name: 'BYK-Chemie',
    logo: import('../media/unternehmen/logos/byk-logo.png'),
    image: import('../media/unternehmen/images/byk-img.jpg'),
    industry: 'Chemicals',
    employees: '2,500',
    main_location: 'Wesel',
    description:
      'At BYK, part of the ALTANA group, we are a globally leading provider of specialty chemicals with over 2,500 employees and a global network of subsidiaries. Our largest site is in Wesel with around 1,000 employees and has existed since 1962. We improve the properties of materials such as scratch resistance, gloss, mechanical strength, flow behavior, light resistance, and flame retardancy. As part of the IMBIT study program, we offer practical training in the IT field. Our students learn about international day-to-day and project business and can look into various commercial departments. They take responsibility from the beginning and present their work results to our IT management twice a year. We also support a semester abroad at an ALTANA location.',
    applyLink:
      'https://www.byk.com/de/unternehmen-news/karriere/ausbildung/imbit-mwd',
    applyLinkShort: 'byk.de',
  },
  {
    id: 2,
    name: 'PwC',
    logo: import('../media/unternehmen/logos/pwc-logo.png'),
    image: import('../media/unternehmen/images/pwc-img.jpg'),
    industry: 'Auditing',
    employees: '327,000',
    main_location: 'Frankfurt a. Main',
    description:
      'PwC Germany, part of the global PricewaterhouseCoopers network, is one of the leading auditing and consulting firms. With over 327,000 employees worldwide, PwC offers comprehensive services in auditing, tax and legal advice, and corporate and management consulting. Our dual study program combines theoretical knowledge with practical experiences in various business areas, supported by experienced mentors and extensive training opportunities. Students have the opportunity to participate in exciting projects and gain valuable insights into the workings of a global company.',
    applyLink: 'https://jobs.pwc.de/de/de/ausbildung',
    applyLinkShort: 'pwc.de',
  },
  {
    id: 3,
    name: 'EY',
    logo: import('../media/unternehmen/logos/ey-logo.png'),
    image: import('../media/unternehmen/images/ey-img.jpg'),
    industry: 'Auditing',
    employees: '365,500',
    main_location: 'Frankfurt a. Main',
    description:
      'The global EY organization is a market-leading company in auditing, tax and legal advice, as well as Strategy and Transactions and Consulting. With our experience and services, we strengthen trust in the economy and financial markets worldwide. We are well equipped for this: with excellently trained employees, strong and diverse teams, excellent services, and proverbial customer service. Our dual study program offers practical training and enables students to gain valuable experience in various business areas and start their careers in an international environment.',
    applyLink:
      'https://www.ey.com/de_de/careers/berufseinstieg-fuer-schueler-innen-ausbildung-studium-bewerbung',
    applyLinkShort: 'ey.com',
  },
  {
    id: 4,
    name: 'Atos',
    logo: import('../media/unternehmen/logos/atos-logo.png'),
    image: import('../media/unternehmen/images/atos-img.jpg'),
    industry: 'Consulting',
    employees: '105,000',
    main_location: 'Frankfurt a. Main',
    description:
      'Atos is a global leader in digital transformation. With approximately 105,000 employees and annual revenue of around 11 billion euros, Atos offers end-to-end solutions for all industries. Atos combines technological expertise with a deep understanding of industry importments. The dual study program at Atos offers practical training in various IT areas and enables students to gain valuable insights into digital transformation. Students have the opportunity to participate in international projects and develop their skills in a dynamic environment.',
    applyLink: 'https://atos.net/de/deutschland/karriere/dualesstudium',
    applyLinkShort: 'atos.de',
  },
  {
    id: 5,
    name: 'Accenture',
    logo: import('../media/unternehmen/logos/accenture-logo.png'),
    image: import('../media/unternehmen/images/accenture-img.jpg'),
    industry: 'Consulting',
    employees: '738,000',
    main_location: 'Frankfurt a. Main',
    description:
      'Accenture is a global leading consulting company offering comprehensive services in strategy, consulting, digital, technology, and operations. With more than 738,000 employees in over 120 countries, we help our clients enhance their performance and create sustainable value. Our dual study programs offer practical training opportunities that prepare students optimally for a career in digital transformation. They benefit from working with experienced consultants and have the opportunity to participate in innovative projects.',
    applyLink: 'https://www.accenture.com/de-de/careers/local/dual-study',
    applyLinkShort: 'accenture.com',
  },
  {
    id: 6,
    name: 'Haribo',
    logo: import('../media/unternehmen/logos/haribo-logo.png'),
    image: import('../media/unternehmen/images/haribo-img.jpg'),
    industry: 'Confectionery',
    employees: '7,000',
    main_location: 'Bonn',
    description:
      'HARIBO is a world-leading manufacturer of fruit gums and licorice, present in more than 120 countries. With over 7,000 employees, HARIBO produces at 16 locations in ten countries and ensures that consumers are always supplied with their favorite products. The dual study program at HARIBO offers practical training opportunities in various areas of confectionery production and administration. Students gain insights into the production and marketing of our products and can develop their skills in a dynamic environment.',
    applyLink: 'https://www.haribo.com/de-de/karriere/einstiegsmoeglichkeiten',
    applyLinkShort: 'haribo.com',
  },
  {
    id: 7,
    name: 'Zeiss',
    logo: import('../media/unternehmen/logos/zeiss-logo.png'),
    image: import('../media/unternehmen/images/zeiss-img.jpg'),
    industry: 'Optical Industry',
    employees: '38,000',
    main_location: 'Oberkochen',
    description:
      'ZEISS is an internationally leading technology group in the optical and optoelectronic industry. As a company wholly owned by a foundation, ZEISS is committed to all its activities and responsibilities. ZEISS challenges the limits of human imagination and creates value for its customers. The dual study program at ZEISS offers comprehensive training opportunities in various technical and commercial areas. Students benefit from practical experiences and the opportunity to participate in significant innovation projects.',
    applyLink:
      'https://www.zeiss.de/corporate/karriere/einstieg-bei-zeiss/schuelerinnen-und-schueler/dein-duales-studium-bei-zeiss.html',
    applyLinkShort: 'zeiss.de',
  },
  {
    id: 8,
    name: 'DLCON',
    logo: import('../media/unternehmen/logos/dlcon-logo.png'),
    image: import('../media/unternehmen/images/dlcon-img.jpg'),
    industry: 'SAP Consulting',
    employees: '30',
    main_location: 'Weinheim',
    description:
      'DLCON enhances the competitiveness and future viability of its customers through reliable and high-quality solutions at an advanced technological level. With a small, highly specialized team, DLCON offers tailored SAP consulting and implementation for various industries. The dual study program at DLCON allows students to directly participate in projects and gain valuable experience in enterprise software. Students benefit from intensive support and the opportunity to continuously develop their skills and knowledge.',
    applyLink: 'https://www.dlcon.de/karriere/ausbildung-bei-dlcon',
    applyLinkShort: 'dlcon.de',
  },
  {
    id: 9,
    name: 'Boehringer Ingelheim',
    logo: import('../media/unternehmen/logos/boehringer-logo.png'),
    image: import('../media/unternehmen/images/boehringer-img.jpg'),
    industry: 'Pharmaceuticals',
    employees: '53,000',
    main_location: 'Ingelheim am Rhein',
    description:
      'Boehringer Ingelheim is a world-leading pharmaceutical company specializing in the research, development, production, and marketing of innovative medicines for humans and animals. With over 53,000 employees in 130 countries, Boehringer Ingelheim is committed to the health and well-being of patients worldwide. The dual study program offers an excellent opportunity to gain practical experience in the pharmaceutical industry and apply theoretical knowledge. Students at Boehringer Ingelheim benefit from practical training and the opportunity to participate in significant research and development projects.',
    applyLink:
      'https://www.meine-zukunft-bei-boehringer-ingelheim.de/berufsuebersicht/duale-studiengaenge/b-sc-wirtschaftsinformatik-dh-mwd/',
    applyLinkShort: 'boehringer-ingelheim.com',
  },
  {
    id: 10,
    name: 'Cosmo Consult',
    logo: import('../media/unternehmen/logos/cosmo-logo.png'),
    image: import('../media/unternehmen/images/cosmo-img.jpg'),
    industry: 'IT Consulting',
    employees: '1,400',
    main_location: 'Hamburg',
    description:
      'Cosmo Consult is a globally leading Microsoft partner for enterprise solutions and digitalization. With over 1,400 employees at locations in Europe, Asia, and America, Cosmo Consult supports its customers in digital transformation and optimizing their business processes. Our expertise includes ERP, CRM, and Business Intelligence solutions, as well as custom software developments. The dual study program offers practical training opportunities in IT consulting and implementation, enabling students to gain valuable insights into working with modern enterprise solutions and digital technologies.',
    applyLink:
      'https://de.cosmoconsult.com/ueber-uns/jobsundkarriere/ausbildungpraktikadiplomarbeiten/',
    applyLinkShort: 'cosmoconsult.com',
  },
  {
    id: 11,
    name: 'DAW',
    logo: import('../media/unternehmen/logos/daw-logo.png'),
    image: import('../media/unternehmen/images/daw-img.jpg'),
    industry: 'Paints and Coatings',
    employees: '5,600',
    main_location: 'Ober-Ramstadt',
    description:
      'DAW is a leading company in the paint and coatings industry in Germany and Europe. As a family-owned company in its fifth generation, we are proud of our innovation and commitment to sustainable products and processes. With around 5,600 employees at various locations, we develop and produce a wide range of paints, coatings, and building protection products. The dual study program offers practical training in various areas, from production to research and development to marketing and sales. Students benefit from comprehensive support and diverse development opportunities.',
    applyLink: 'https://daw-karriere.de/stellensuche/',
    applyLinkShort: 'daw.de',
  },
  {
    id: 12,
    name: 'Dentsply Sirona',
    logo: import('../media/unternehmen/logos/dentsply-logo.png'),
    image: import('../media/unternehmen/images/dentsply-img.jpg'),
    industry: 'Medical Technology',
    employees: '16,200',
    main_location: 'Bensheim',
    description:
      "Dentsply Sirona is the world's largest manufacturer of dental products and technologies. With over 16,200 employees in more than 40 countries, we develop, produce, and distribute innovative solutions for dentists and dental technicians. Our products and technologies contribute to improving dental health and patients' quality of life worldwide. The dual study program offers practical training, providing insights into various areas of medical technology, from research and development to production and marketing and sales. Students benefit from a dynamic work environment and diverse career opportunities.",
    applyLink:
      'https://career.dentsplysirona.com/de/schueler/duales-studium.html',
    applyLinkShort: 'dentsplysirona.com',
  },
  {
    id: 13,
    name: 'Döhler',
    logo: import('../media/unternehmen/logos/doehler-logo.png'),
    image: import('../media/unternehmen/images/doehler-img.jpg'),
    industry: 'Beverage & Food Industry',
    employees: '8,000',
    main_location: 'Darmstadt',
    description:
      'Döhler is a globally leading manufacturer of natural ingredients, ingredient systems, and integrated solutions for the food and beverage industry. With over 8,000 employees in more than 30 countries, we develop innovative products and concepts that meet current market trends and consumer needs. Our dual study programs offer practical training that prepares students optimally for a career in the food and beverage industry. They gain insights into various areas such as product development, quality management, and sales and benefit from extensive training opportunities.',
    applyLink:
      'https://jobs.doehler.com/job/Darmstadt-Duales-Studium-Bachelor-of-Science-Internationale-Wirtschaftsinformatik-%28IMBIT%29-ab-01_10_2023-HE-64295/841820501/',
    applyLinkShort: 'doehler.com',
  },
  {
    id: 14,
    name: 'e-shelter security',
    logo: import('../media/unternehmen/logos/e-shelter-logo.png'),
    image: import('../media/unternehmen/images/e-shelter-img.jpg'),
    industry: 'Security Technology',
    employees: 250,
    main_location: 'Frankfurt am Main',
    description:
      'e-shelter security offers innovative security solutions and services for businesses and public institutions. With a team of around 250 employees, we develop and implement tailored security concepts that meet the highest standards and are customized to the individual needs of our customers. Our expertise includes access control, video surveillance, intrusion detection technology, and fire protection. The dual study program offers practical training that prepares students optimally for a career in security technology.',
    applyLink: 'https://e-shelter-security.jobs.personio.de/',
    applyLinkShort: 'e-shelter-security.de',
  },
  {
    id: 15,
    name: 'equens Worldline',
    logo: import('../media/unternehmen/logos/equensworldline-logo.png'),
    image: import('../media/unternehmen/images/equensworldline-img.jpg'),
    industry: 'Payment Systems and Transactions',
    employees: '18,000',
    main_location: 'Frankfurt am Main',
    description:
      'Worldline is a globally leading provider of secure payment services and reliable transactions. With over 18,000 employees in more than 50 countries, we offer comprehensive solutions for payments that meet the highest security standards and the demands of modern commerce. Our dual study programs offer practical training that prepares students optimally for a career in the payments and transactions industry. They gain insights into various areas such as payment processing, risk management, and IT security and benefit from extensive training opportunities.',
    applyLink:
      'https://de.worldline.com/de/home/blog/2020/july1/start-in-den-job-bei-worldline.html',
    applyLinkShort: 'worldline.com',
  },
  {
    id: 16,
    name: 'Evonik',
    logo: import('../media/unternehmen/logos/evonik-logo.png'),
    image: import('../media/unternehmen/images/evonik-img.jpg'),
    industry: 'Chemical Industry',
    employees: '33,000',
    main_location: 'Essen',
    description:
      "Evonik is a globally leading company in specialty chemicals, developing innovative solutions for various industries. With around 33,000 employees in over 100 countries, we offer a broad portfolio of products and services that contribute to improving the quality of life and conserving the earth's resources. Our dual study programs offer practical training in various areas of the chemical industry, from research and development to production and marketing and sales. Students benefit from extensive training opportunities and the chance to participate in significant innovation projects.",
    applyLink:
      'https://ausbildung.evonik.de/offer/ausbildung-bachelor-of-science-inte/573141',
    applyLinkShort: 'evonik.de',
  },
  {
    id: 17,
    name: 'Fresenius Technologies',
    logo: import('../media/unternehmen/logos/fresenius-logo.png'),
    image: import('../media/unternehmen/images/fresenius-img.jpg'),
    industry: 'Healthcare Services',
    employees: '316,000',
    main_location: 'Bad Homburg',
    description:
      "Fresenius is a global healthcare group offering products and services for dialysis, hospitals, and outpatient medical care. With around 316,000 employees worldwide, we are committed to improving patients' quality of life and promoting health. Our dual study programs offer practical training in various areas of healthcare, from medical technology to hospital management to research and development. Students benefit from extensive training opportunities and the chance to participate in significant projects to improve healthcare.",
    applyLink:
      'https://karriere.fresenius.de/ihr-einstieg/schuelerinnen/ausbildung-und-duales-studium/bachelor-of-science-international-management-for-business-and-information-technology-mwd#tc_9837b9a7_7739_4cd8_a1d4_1cdbb850ec49_tab1',
    applyLinkShort: 'fresenius.de',
  },
  {
    id: 18,
    name: 'John Deere',
    logo: import('../media/unternehmen/logos/johndeere-logo.png'),
    image: import('../media/unternehmen/images/johndeere-img.jpg'),
    industry: 'Agricultural Machinery',
    employees: '2,780',
    main_location: 'Walldorf',
    description:
      'John Deere is the world-leading manufacturer of agricultural machinery, offering a wide range of machines and services for agriculture, horticulture, and forestry. With around 2,780 employees in Germany, we are committed to developing innovative and sustainable solutions that meet the demands of modern agriculture. Our dual study programs offer practical training in various areas of mechanical engineering and agricultural technology, from research and development to production and sales and service. Students benefit from extensive training opportunities and the chance to participate in significant innovation projects.',
    applyLink:
      'https://www.deere.de/de/unser-unternehmen/john-deere-karriere/lehrlinge-studenten-uber-praktika/zu-den-dualen-studiengängen/',
    applyLinkShort: 'deere.de',
  },
  {
    id: 19,
    name: 'KSB',
    logo: import('../media/unternehmen/logos/ksb-logo.png'),
    image: import('../media/unternehmen/images/ksb-img.jpg'),
    industry: 'Industrial Valves and Pumps',
    employees: '15,000',
    main_location: 'Frankenthal',
    description:
      "KSB is one of the world's leading manufacturers of pumps, valves, and related systems. With around 15,000 employees in over 100 countries, we offer innovative and reliable solutions for various applications in the fields of water, wastewater, energy, industry, and building technology. Our dual study programs offer practical training in various technical and commercial areas, preparing students optimally for a career in the pump and valve industry. They benefit from extensive training opportunities and the chance to participate in significant projects.",
    applyLink:
      'https://www.ksb.com/de-de/unternehmen/karriere/stellenangebote/jobarticle/dh-studium-wirtschaftsinformatik-imbit-bachelor-of-science-fuer-2023-639290',
    applyLinkShort: 'ksb.com',
  },
  {
    id: 20,
    name: 'mind solutions',
    logo: import('../media/unternehmen/logos/mindsolutions-logo.png'),
    image: import('../media/unternehmen/images/mindsolutions-img.jpg'),
    industry: 'SAP Consulting',
    employees: 'n/a',
    main_location: 'Heidelberg',
    description:
      "Mind Solutions is a specialist in implementing SAP applications in warehouse management. With a team of experienced consultants, we offer tailored solutions to optimize our customers' warehouse and logistics processes. Our dual study programs offer practical training that prepares students optimally for a career in SAP consulting. They gain insights into various areas such as system integration, process optimization, and project management and benefit from extensive training opportunities.",
    applyLink:
      'https://www.mindsolutions-it.com/wp-content/uploads/Stellenbeschreibung-SAP-Duales-Studium-IMBIT.pdf',
    applyLinkShort: 'mindsolutions-it.com',
  },
  {
    id: 21,
    name: 'Protiviti',
    logo: import('../media/unternehmen/logos/protiviti-logo.png'),
    image: import('../media/unternehmen/images/protiviti-img.jpg'),
    industry: 'Consulting',
    employees: '5,700',
    main_location: 'Frankfurt am Main',
    description:
      'Protiviti is a global consulting firm specializing in risk management, business processes, and technology consulting. With around 5,700 employees in more than 20 countries, we help our clients identify and manage risks, optimize business processes, and implement technological innovations. Our dual study programs offer practical training in various areas of consulting, preparing students optimally for a career in consulting. They benefit from extensive training opportunities and the chance to participate in significant projects.',
    applyLink: 'https://www.protiviti.com/de-de/careers',
    applyLinkShort: 'protiviti.com',
  },
  {
    id: 22,
    name: 'Röhm',
    logo: import('../media/unternehmen/logos/roehm-logo.png'),
    image: import('../media/unternehmen/images/roehm-img.jpg'),
    industry: 'Chemical Industry',
    employees: '3,500',
    main_location: 'Darmstadt',
    description:
      'Röhm is a leading provider of methacrylate chemistry, developing innovative products for a variety of applications, including automotive, electronics, medical, and construction. With around 3,500 employees worldwide, we focus on sustainability and technological excellence. Our dual study programs offer practical training in various areas of the chemical industry, preparing students optimally for a career in the industry. They gain insights into research and development, production, and sales and benefit from extensive training opportunities.',
    applyLink: 'https://www.roehm.com/de/ausbildung',
    applyLinkShort: 'roehm.com',
  },
  {
    id: 23,
    name: 'Schaeffler Technologies',
    logo: import('../media/unternehmen/logos/schaeffler-logo.png'),
    image: import('../media/unternehmen/images/schaeffler-img.jpg'),
    industry: 'Mechanical Engineering',
    employees: '83,000',
    main_location: 'Herzogenaurach',
    description:
      'Schaeffler is a global automotive and industrial supplier developing innovative solutions for a variety of applications. With around 83,000 employees worldwide, we focus on technological excellence and sustainability. Our dual study programs offer practical training in various technical and commercial areas, preparing students optimally for a career in the mechanical engineering industry. They benefit from extensive training opportunities and the chance to participate in significant projects.',
    applyLink:
      'https://www.schaeffler.de/de/karriere/ihr-einstieg/schueler/b_of_science_internationales_management/',
    applyLinkShort: 'schaeffler.de',
  },
  {
    id: 24,
    name: 'DB Schenker',
    logo: import('../media/unternehmen/logos/dbschenker-logo.png'),
    image: import('../media/unternehmen/images/dbschenker-img.jpg'),
    industry: 'Transport and Logistics',
    employees: '76,000',
    main_location: 'Berlin',
    description:
      'DB Schenker is a globally leading provider of logistics and supply chain solutions. With around 76,000 employees in more than 130 countries, we offer comprehensive services in land transport, air and sea freight, contract logistics, and supply chain management. Our dual study programs offer practical training in various areas of logistics and supply chain management, preparing students optimally for a career in the logistics industry. They benefit from extensive training opportunities and the chance to participate in significant projects.',
    applyLink:
      'https://karriere.deutschebahn.com/karriere-de/dein-einstieg/duales-studium',
    applyLinkShort: 'bahn.de',
  },
  {
    id: 25,
    name: 'BAUHAUS',
    logo: import('../media/unternehmen/logos/bauhaus-logo.png'),
    image: import('../media/unternehmen/images/bauhaus-img.jpg'),
    industry: 'Retail',
    employees: '35,000',
    main_location: 'Mannheim',
    description:
      'BAUHAUS is one of the largest DIY store chains in Europe, offering a wide range of products for DIY enthusiasts, gardeners, and construction professionals. With more than 270 specialty centers in 19 countries and over 35,000 employees, BAUHAUS stands for quality, service, and expert advice. The dual study program offers practical training that provides insights into various business areas such as purchasing, sales, logistics, and marketing. Students benefit from extensive training opportunities and the chance to develop in a dynamic and innovative work environment.',
    applyLink: 'https://jobs.bauhaus.info',
    applyLinkShort: 'bauhaus.info',
  },
  {
    id: 26,
    name: 'cbs',
    logo: import('../media/unternehmen/logos/cbs-logo.png'),
    image: import('../media/unternehmen/images/cbs-img.jpg'),
    industry: 'Consulting',
    employees: '1300',
    main_location: 'Heidelberg',
    description:
      'cbs Corporate Business Solutions is a leading consulting company for global business process solutions and SAP implementations. With around 1300 employees worldwide, we support companies in their digital transformation and the optimization of their business processes. Our dual study programs offer practical training in various areas of consulting, preparing students optimally for a career in consulting. They benefit from extensive training opportunities and the chance to participate in significant projects.',
    applyLink:
      'https://www.cbs-consulting.com/karriere-bei-cbs/stellenangebote/',
    applyLinkShort: 'cbs-consulting.com',
  },
  {
    id: 27,
    name: 'GIZ',
    logo: import('../media/unternehmen/logos/giz-logo.png'),
    image: import('../media/unternehmen/images/giz-img.jpg'),
    industry: 'Development Cooperation',
    employees: '25,000',
    main_location: 'Bonn',
    description:
      'The Deutsche Gesellschaft für Internationale Zusammenarbeit (GIZ) is a globally active federal company in development cooperation. With over 25,000 employees, GIZ supports the federal government in achieving its development policy goals and contributes to sustainable development in partner countries. Our dual study programs offer practical training that prepares students optimally for a career in development cooperation. They benefit from extensive training opportunities and the chance to participate in significant projects.',
    applyLink: 'https://www.giz.de/de/html/jobs.html',
    applyLinkShort: 'giz.de',
  },
  {
    id: 28,
    name: 'Linde Gases Division',
    logo: import('../media/unternehmen/logos/linde-logo.png'),
    image: import('../media/unternehmen/images/linde-img.jpg'),
    industry: 'Industrial Gases',
    employees: '66,000',
    main_location: 'Pullach',
    description:
      'The Linde Gases Division is a leading provider of industrial gases and technical services. With around 66,000 employees worldwide, Linde delivers innovative solutions for a variety of industries, including chemicals, metallurgy, food production, and healthcare. Our dual study programs offer practical training that prepares students optimally for a career in the industrial gas industry. They benefit from extensive training opportunities and the chance to participate in significant projects.',
    applyLink: 'https://linde.csod.com/ux/ats/careersite/20/home?c=linde',
    applyLinkShort: 'linde.com',
  },
  {
    id: 29,
    name: 'SWAN',
    logo: import('../media/unternehmen/logos/swan-logo.png'),
    image: import('../media/unternehmen/images/swan-img.jpg'),
    industry: 'SAP Consulting',
    employees: '150',
    main_location: 'Augsburg',
    description:
      'SWAN GmbH is a specialized SAP partner for logistics solutions. With a team of 150 employees, SWAN offers tailored SAP solutions to optimize the warehouse management and logistics processes of its customers. Our dual study programs offer practical training that prepares students optimally for a career in SAP consulting. They benefit from extensive training opportunities and the chance to participate in significant projects.',
    applyLink: 'https://swan.de/ueber-swan/karriere/',
    applyLinkShort: 'swan.de',
  },
  {
    id: 30,
    name: 'TE Connectivity',
    logo: import('../media/unternehmen/logos/teconnectivity-logo.png'),
    image: import('../media/unternehmen/images/teconnectivity-img.jpg'),
    industry: 'Electronics',
    employees: '80,000',
    main_location: 'Bensheim',
    description:
      'TE Connectivity is a globally leading provider of connectivity technology and sensor solutions. With around 80,000 employees in over 50 countries, TE Connectivity develops innovative products and solutions for the automotive, aerospace, telecommunications, and electronics industries. Our dual study programs offer practical training that prepares students optimally for a career in the electronics industry. They benefit from extensive training opportunities and the chance to participate in significant projects.',
    applyLink: 'https://careers.te.com/',
    applyLinkShort: 'te.com',
  },
  {
    id: 31,
    name: 'Unilever',
    logo: import('../media/unternehmen/logos/unilever-logo.png'),
    image: import('../media/unternehmen/images/unilever-img.jpg'),
    industry: 'Consumer Goods',
    employees: '150,000',
    main_location: 'Hamburg',
    description:
      "Unilever is one of the world's leading consumer goods companies, offering products in nutrition, hygiene, and household care. With over 150,000 employees worldwide, Unilever is committed to sustainable growth and social responsibility. Our dual study programs offer practical training that prepares students optimally for a career in the consumer goods industry. They benefit from extensive training opportunities and the chance to participate in significant projects.",
    applyLink: 'https://karriere.unilever.de',
    applyLinkShort: 'unilever.de',
  },
]);

export const lecturers = shuffleArray([
  {
    id: 1,
    name: 'Volkmar Petrich',
    image: import('../media/dozenten/petrich-img.jpg'),
    courses:
      'Accounting and Basic Principles of Annual Financial Statement Analysis',
    cv: [
      {
        year: 'since 2014',
        position: 'Interim Manager, Lecturer and Listed Business Angel',
      },
      {
        year: '2005-2014',
        position:
          'Vice President Controlling Procurement & Fleet at Deutsche Post DHL, Bonn',
      },
      {
        year: '2002-2005',
        position:
          'Head of Finance & Controlling Worldwide at Linde AG, Refrigeration Division, Cologne',
      },
      {
        year: '1997-2002',
        position:
          'Director Regional Controlling at BASF Pharma, Ludwigshafen and London',
      },
    ],
  },
  {
    id: 2,
    name: 'Stephanie Lempart',
    image: import('../media/dozenten/lempart-img.jpg'),
    courses: 'IT Project Management and Service Management',
    cv: [
      {
        year: 'since 2016',
        position:
          'Innovation Consulting and Studies, Focus: Energy and Mobility',
      },
      {
        year: '2011-2013',
        position:
          'E.ON Vertrieb Deutschland GmbH, Munich, Project Management Innovation Projects and Strategy',
      },
      {
        year: '2008-2010',
        position:
          'E.ON Vertrieb Deutschland GmbH, Munich, Procurement and Contract Management',
      },
      {
        year: '2007-2008',
        position:
          'E.ON Ruhrgas AG, Essen, Strategic Consulting for Energy Supply Companies and Utilities',
      },
    ],
  },
  {
    id: 3,
    name: 'Volker Harms',
    image: import('../media/dozenten/no-img.jpg'),
    courses: 'Databases I/II',
    cv: [
      {
        year: '30 years',
        position:
          'SAP as a Base Developer with C/C++ and ABAP, Service Manager Software Delivery and Product Owner LM PCA',
      },
    ],
  },
  {
    id: 4,
    name: 'Haley Tarnow',
    image: import('../media/dozenten/tarnow-img.jpg'),
    courses: 'Business English I/II',
    cv: [
      {
        year: '20 years',
        position: 'Lecturer in English in Mexico, Italy and Germany',
      },
      {
        year: 'since 2019',
        position: 'Brand Ambassador Genuine Health',
      },
      {
        year: 'since 2018',
        position: 'Territory Sales Manager, Mondelez',
      },
    ],
  },
  {
    id: 5,
    name: 'Prof. Dr.-Ing. Stefan Noll',
    image: import('../media/dozenten/noll-img.jpg'),
    courses: 'Web Programming, Distributed Systems',
    cv: [
      {
        year: 'since 2011',
        position:
          'Professor "Software Engineering for Business Applications" at the Furtwangen University',
      },
      {
        year: 'since 2000',
        position:
          'Evaluator and Reviewer for Research Proposals and Projects at the European Commission, Brussels, Belgium',
      },
      {
        year: '1992-2006',
        position:
          'Department Head "Communication and Cooperation" at the Fraunhofer Institute for Computer Graphics Research (IGD)',
      },
    ],
  },
  {
    id: 6,
    name: 'Simone Krug',
    image: import('../media/dozenten/krug-img.jpg'),
    courses:
      'Basics of IT, Academic Writing I/II, Human Machine Interaction, New Concepts II',
    cv: [
      {
        year: 'since 2021',
        position: 'Professor at DHBW Mannheim',
      },
      {
        year: '2014-2021',
        position: 'Independent Consultant in the field of Compliance/GxP',
      },
      {
        year: '2007-2013',
        position:
          'PhD at the University of Mannheim with teaching and research stays in the USA and Australia',
      },
    ],
  },
  {
    id: 7,
    name: 'Dr. Robert Lang',
    image: import('../media/dozenten/lang-img.jpg'),
    courses: 'Analysis and Linear Algebra, Business Mathematics, Statistics',
    cv: [
      {
        year: 'since 2009',
        position: 'Lecturer at DHBW Mannheim',
      },
      {
        year: '30 years',
        position: 'Software Development at SAP SE, Walldorf',
      },
      {
        year: '6 years',
        position:
          'Research Associate in Mathematics at the University of Trier',
      },
    ],
  },
  {
    id: 8,
    name: 'Kai Holschuh',
    image: import('../media/dozenten/holschuh-img.jpg'),
    courses: 'Fundamentals of Business Administration',
    cv: [
      {
        year: 'since 2013',
        position: 'Professor at DHBW Mannheim',
      },
      {
        year: '2001-2009',
        position: 'Independent Consultant, e.g. for Credit Suisse',
      },
      {
        year: '1999-2001',
        position: 'Managing Director at Fonoservices, Barcelona',
      },
    ],
  },
  {
    id: 9,
    name: 'Rainer Mewaldt',
    image: import('../media/dozenten/mewaldt-img.jpg'),
    courses: 'Cloud Computing',
    cv: [
      {
        year: 'since 2011',
        position: 'Head of PLM Cloud Services at Eviden/Atos',
      },
      {
        year: '2008-2011',
        position: 'Global Portfolio Manager for PLM at Siemens SIS',
      },
      {
        year: '2003-2008',
        position:
          'Consulting and Project Management of PLM projects at consulting firms CSC and BearingPoint',
      },
    ],
  },
  {
    id: 10,
    name: 'Dr. Cristos Velasco',
    image: import('../media/dozenten/velasco-img.jpg'),
    courses:
      'Information Technology Law, International Business Law & International Organizations',
    cv: [
      {
        year: '2020-2024',
        position:
          'Senior Research Fellow and Member of the Outreach Committee Board at the Center for AI and Digital Policy (CAIDP)',
      },
      {
        year: 'since 2012',
        position:
          'External Lecturer Law and Policy in the IMBIT Division of DHBW in Mannheim and Stuttgart',
      },
      {
        year: 'since 2011',
        position:
          'Independent Legal Advisor and Trainer in the fields of Cybercrime, Cybersecurity and Data Protection',
      },
      {
        year: 'since 2008',
        position:
          'Executive Director Protección Datos México (ProtDataMx) and Evidencia Digital.Lat',
      },
    ],
  },
  {
    id: 11,
    name: 'Christoph Altenhofen',
    image: import('../media/dozenten/altenhofen-img.jpg'),
    courses: 'Business Process Management',
    cv: [
      {
        year: 'since 1996',
        position:
          'Head of IAO Center for Document and Workflow Management at the Fraunhofer Institute',
      },
    ],
  },
  {
    id: 12,
    name: 'Pablo Oliveira Antonino',
    image: import('../media/dozenten/antonino-img.jpg'),
    courses: 'Software Lifecycle',
    cv: [
      {
        year: 'since 2019',
        position: 'Lecturer - Computer Science at TU Kaiserslautern',
      },
      {
        year: 'since 2017',
        position:
          'Department Head "Virtual Engineering" at the Fraunhofer Institute for Experimental Software Engineering IESE',
      },
      {
        year: '2009-2017',
        position:
          'Engineer/Project Manager at the Fraunhofer Institute for Experimental Software Engineering IESE',
      },
    ],
  },
  {
    id: 13,
    name: 'Prof. Dr. Harald Bendl',
    image: import('../media/dozenten/bendl-img.jpg'),
    courses:
      'Program Director, Supply Chain Management, Project Management, Finance',
    cv: [
      {
        year: 'since 2011',
        position: 'Professor at DHBW',
      },
      {
        year: '2007-2010',
        position: 'Madison Advisors Management Consultants, Bucharest, Partner',
      },
      {
        year: '1994-2007',
        position:
          'Horváth & Partners Management Consulting Senior Consultant, Senior Project Manager, Prokurist',
      },
    ],
  },
  {
    id: 14,
    name: 'Timm Marheine',
    image: import('../media/dozenten/marheine-img.jpg'),
    courses: 'Introduction to IT',
    cv: [
      {
        year: 'since 2009',
        position: 'Managing Director of DLCON AG',
      },
      {
        year: '2017-2021',
        position: 'Vice President IT (Interim) at Carcoustics',
      },
      {
        year: '2015-2016',
        position: 'IT Project Manager at GAZPROM Germania GmbH',
      },
    ],
  },
  {
    id: 15,
    name: 'Rainer Hoch',
    image: import('../media/dozenten/no-img.jpg'),
    courses: 'Program Director',
    cv: [
      {
        year: 'since 2003',
        position: 'Professor and Program Director IMBIT at DHBW Mannheim',
      },
      {
        year: '1996-2003',
        position: 'Development Architect, Basis Technology Development at SAP',
      },
      {
        year: '1991-1995',
        position:
          'Scientist and Project Manager, Document Analysis at DFKI in Kaiserslautern',
      },
      {
        year: '1989-1991',
        position:
          'Scientist, Electronic Publishing and Document Standards, GMD-IPSI, Darmstadt (now Fraunhofer Institute)',
      },
    ],
  },
]);

export const alumni = shuffleArray([
  {
    id: 1,
    name: 'Jonathan Frölich',
    image: import('../media/alumni/froelich-img.jpeg'),
    graduationYear: 2020,
    currentJob: 'Senior Consultant',
    currentCompany: 'BCG Platinion',
    cv: [
      {
        year: 'since 2024',
        position: 'Senior Consultant at BCG Platinion',
      },
      {
        year: '2022-2023',
        position: 'IT Consultant at BCG Platinion',
      },
      {
        year: '2020-2022',
        position: 'Junior Management Consultant at Atos',
      },
      {
        year: '2018-2020',
        position: 'Dual Student at Atos',
      },
      {
        year: '2014-2017',
        position: 'Operations Technical Assistant at TU Munich',
      },
    ],
  },
  {
    id: 2,
    name: 'Kadir Acar',
    image: import('../media/alumni/acar-img.jpeg'),
    graduationYear: 2012,
    currentJob: 'Head of Transformation Management',
    currentCompany: 'E.ON Digital Technology',
    cv: [
      {
        year: 'since 2024',
        position:
          'Head of Transformation Management at E.ON Digital Technology',
      },
      {
        year: '2020-2024',
        position: 'VP Digital Sales Platform at E.ON Germany',
      },
      {
        year: '2015-2020',
        position: 'Manager at BCG Platinion',
      },
      {
        year: '2012-2015',
        position: 'Senior Consultant at IBM',
      },
    ],
  },
  {
    id: 3,
    name: 'Fabio Mörmann',
    image: import('../media/alumni/moermann-img.jpeg'),
    graduationYear: 2015,
    currentJob: 'VP of Technology',
    currentCompany: 'Digital Science',
    cv: [
      {
        year: 'since 2024',
        position: 'VP of Technology at Digital Science',
      },
      {
        year: '2022-2024',
        position:
          'Technical Consultant/Digital Strategy Advisor at Cloudflight',
      },
      {
        year: '2018-2022',
        position: 'IT Project Lead at CONWEAVER GmbH',
      },
      {
        year: '2015-2018',
        position: 'Software Developer at Heidelberg Instruments',
      },
      {
        year: '2012-2015',
        position: 'Dual Student at Accenture',
      },
    ],
  },
]);
