import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { FormattedMessage } from 'react-intl';
import arrowIcon from '../general/media/icons/arrow.svg';
import closeIcon from '../general/media/icons/close.svg';
import data from './data/auslandsberichte.js';
import './styles/AuslandPraxis.css';

const ESCAPE_KEY = 'Escape';

/**
 * Main component for AuslandPraxis page
 * Handles displaying a list of Ausland cards and their details in an overlay.
 */
const AuslandPraxis = () => {
  const cardContainerRef = useRef();
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const [openCard, setOpenCard] = useState(null);

  // Function to close the overlay and reset the open card state
  const toggleOverlayVisibility = () => {
    setIsOverlayVisible(false);
    setOpenCard(null);
  };

  // Function to handle opening a card
  const handleCardOpen = (cardName) => {
    setOpenCard(cardName);
    setIsOverlayVisible(true);
    document.body.style.overflow = 'hidden';
  };

  // Effect to handle escape key and click outside to close the overlay
  useEffect(() => {
    const handleEscapeKey = (event) => {
      if (event.key === ESCAPE_KEY && isOverlayVisible) {
        setIsOverlayVisible(false);
        setOpenCard(null);
        document.body.style.overflow = '';
      }
    };

    const handleClickOutside = (event) => {
      if (
        cardContainerRef.current &&
        !cardContainerRef.current.contains(event.target)
      ) {
        setIsOverlayVisible(false);
        setOpenCard(null);
        document.body.style.overflow = '';
      }
    };

    document.addEventListener('keydown', handleEscapeKey);
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('keydown', handleEscapeKey);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOverlayVisible]);

  // Function to handle scrolling of cards
  const scrollCards = (direction) => {
    if (cardContainerRef.current) {
      const container = cardContainerRef.current;
      const cards = container.getElementsByClassName('auslandcards__card');
      if (cards.length > 0) {
        // Find the current scroll position
        const currentScrollLeft = container.scrollLeft;
        let targetScrollLeft = 0;

        if (direction > 0) {
          // Scrolling to the right
          for (let i = 0; i < cards.length; i++) {
            const card = cards[i];
            const cardLeftEdge = card.offsetLeft;
            if (
              cardLeftEdge + card.offsetWidth >
              currentScrollLeft + container.clientWidth
            ) {
              targetScrollLeft = cardLeftEdge;
              break;
            }
          }
        } else {
          // Scrolling to the left
          for (let i = 0; i < cards.length; i++) {
            const card = cards[i];
            const cardLeftEdge = card.offsetLeft;
            if (cardLeftEdge < currentScrollLeft) {
              targetScrollLeft = cardLeftEdge;
              break;
            }
          }
          // If no card is found, set scroll position to 0
          if (targetScrollLeft === 0 && currentScrollLeft !== 0) {
            targetScrollLeft = 0;
          }
        }

        // Calculate the scroll amount and scroll the container
        const scrollAmount = targetScrollLeft - currentScrollLeft;
        container.scrollBy({
          left: scrollAmount,
          behavior: 'smooth',
        });
      }
    }
  };

  // Generate card components from data
  const cards = data.map((item) => (
    <AuslandCard
      key={item.name}
      item={item}
      isOpen={openCard === item.name}
      onOpen={() => handleCardOpen(item.name)}
      onClose={toggleOverlayVisibility}
    />
  ));

  return (
    <div className='AuslandPraxis padding-container padding-top'>
      <Helmet>
        <meta
          name='description'
          content='Erfahren Sie mehr über die Auslandserfahrungen unserer Praktikanten bei verschiedenen Unternehmen weltweit. Lesen Sie ihre Geschichten und holen Sie sich wertvolle Tipps für Ihre eigene Praxisphase im Ausland.'
        />
        <meta
          name='keywords'
          content='Auslandspraxis, Praktikum im Ausland, Erfahrungsberichte, internationale Praktika, Auslandspraktikum, Praktikumserfahrungen, Karriere im Ausland'
        />
        <meta name='robots' content='index, follow' />
        <meta
          property='og:title'
          content='Praxisphase im Ausland | Erfahrungsberichte'
        />
        <meta
          property='og:description'
          content='Erfahren Sie mehr über die Auslandserfahrungen unserer Praktikanten bei verschiedenen Unternehmen weltweit. Lesen Sie ihre Geschichten und holen Sie sich wertvolle Tipps für Ihre eigene Praxisphase im Ausland.'
        />
        <meta property='og:url' content='https://imbit-n3xt.com/Ausland' />
        <meta name='twitter:card' content='summary_large_image' />
        <meta
          name='twitter:title'
          content='Praxisphase im Ausland | Erfahrungsberichte'
        />
        <meta
          name='twitter:description'
          content='Erfahren Sie mehr über die Auslandserfahrungen unserer Praktikanten bei verschiedenen Unternehmen weltweit. Lesen Sie ihre Geschichten und holen Sie sich wertvolle Tipps für Ihre eigene Praxisphase im Ausland.'
        />
      </Helmet>
      {isOverlayVisible && <Overlay onClick={toggleOverlayVisibility} />}
      <h2 className='auslandcards__header'>
        <FormattedMessage
          id='AuslandPraxis.internshipAbroad'
          defaultMessage='Praxisphase im Ausland'
        />
      </h2>
      <div className='auslandcards__container'>
        <IconButton
          onClick={() => scrollCards(-1)}
          icon={arrowIcon}
          className='auslandcards__button--left'
        />
        <section
          ref={cardContainerRef}
          className='auslandcards__card-container'
        >
          {cards}
        </section>
        <IconButton
          onClick={() => scrollCards(1)}
          icon={arrowIcon}
          className='auslandcards__button--right'
        />
      </div>
    </div>
  );
};

/**
 * Component to represent each Ausland card
 * @param {Object} props - Properties passed to the component
 * @param {Object} props.item - Data object representing the card content
 * @param {boolean} props.isOpen - Boolean indicating if the card is currently open
 * @param {Function} props.onOpen - Function to call when the card is opened
 * @param {Function} props.onClose - Function to call when the card is closed
 * @returns {JSX.Element} - Rendered component
 */
const AuslandCard = ({ item, isOpen, onOpen, onClose }) => {
  const handleCardClick = () => {
    if (isOpen) {
      onClose(); // If the card is open, close it
    } else {
      onOpen(); // If the card is closed, open it
    }
  };

  return (
    <div className='auslandcards__card'>
      <div className='auslandcards__front' onClick={handleCardClick}>
        <img src={item.img} alt='' className='auslandcards__img' />
        <h3 className='auslandcards__city'>
          {item.city} @{item.company}
        </h3>
        <p className='auslandcards__name'>{item.name}</p>
        <p className='auslandcards__department'>{item.abteilung}</p>
      </div>
      {isOpen && (
        <CardDetails
          item={item}
          onClose={() => {
            onClose();
          }}
        />
      )}
    </div>
  );
};

/**
 * Component to represent the details of each Ausland card
 * @param {Object} props - Properties passed to the component
 * @param {Object} props.item - Data object representing the card content
 * @param {Function} props.onClose - Function to call when the card details are closed
 * @param {Object} ref - Ref to attach to the component
 * @returns {JSX.Element} - Rendered component
 */
const CardDetails = React.forwardRef(({ item, onClose }, ref) => (
  <div
    className='auslandcards__back-large'
    ref={ref}
    onClick={(event) => event.stopPropagation()}
  >
    <div className='auslandcards__back-text--large'>
      <IconButton
        onClick={(event) => {
          event.stopPropagation();
          onClose();
          document.body.style.overflow = '';
        }}
        icon={closeIcon}
        className='auslandcards__close-button'
      />
      <div className='auslandcards__back-text--scrollable'>
        <CardDetail label='Standort:' detail={item.standort} />
        <CardDetail label='Das habe ich gelernt:' detail={item.gelernt} />
        <CardDetail
          label='Was war das beste Erlebnis:'
          detail={item.erlebnisse}
        />
        <CardDetail label='Tipps & Tricks:' detail={item.tipps} />
      </div>
    </div>
  </div>
));

/**
 * Component to represent each detail in the card details
 * @param {Object} props - Properties passed to the component
 * @param {string} props.label - Label for the detail
 * @param {JSX.Element} props.detail - Detail content
 * @returns {JSX.Element} - Rendered component
 */
const CardDetail = ({ label, detail }) => (
  <p className='auslandcards__back-text-detail'>
    <span className='auslandcards__back-text-highlight'>{label}</span> {detail}
  </p>
);

/**
 * Component for Icon button
 * @param {Object} props - Properties passed to the component
 * @param {Function} props.onClick - Function to call when the button is clicked
 * @param {string} props.className - Additional class names for styling
 * @param {string} props.icon - URL of the icon image
 * @returns {JSX.Element} - Rendered component
 */
const IconButton = ({ onClick, className, icon }) => (
  <div
    onClick={onClick}
    className={`auslandcards__button auslandcards__button--close ${
      className || ''
    }`.trim()}
  >
    <img className='auslandcards__button-img' src={icon} alt='Icon' />
  </div>
);

/**
 * Component for overlay
 * @param {Object} props - Properties passed to the component
 * @param {Function} props.onClick - Function to call when the overlay is clicked
 * @param {Object} ref - Ref to attach to the component
 * @returns {JSX.Element} - Rendered component
 */
const Overlay = React.forwardRef(({ onClick }, ref) => (
  <div className='auslandcards__overlay' ref={ref} onClick={onClick} />
));

export default AuslandPraxis;
