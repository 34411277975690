// src/components/tinbit/data/data.js
import bykLogo from '../media/unternehmen/logos/byk-logo.png';
import bykImage from '../media/unternehmen/images/byk-img.jpg';
import pwcLogo from '../media/unternehmen/logos/pwc-logo.png';
import pwcImage from '../media/unternehmen/images/pwc-img.jpg';
import eyLogo from '../media/unternehmen/logos/ey-logo.png';
import eyImage from '../media/unternehmen/images/ey-img.jpg';
import atosLogo from '../media/unternehmen/logos/atos-logo.png';
import atosImage from '../media/unternehmen/images/atos-img.jpg';
import accentureLogo from '../media/unternehmen/logos/accenture-logo.png';
import accentureImage from '../media/unternehmen/images/accenture-img.jpg';
import hariboLogo from '../media/unternehmen/logos/haribo-logo.png';
import hariboImage from '../media/unternehmen/images/haribo-img.jpg';
import zeissLogo from '../media/unternehmen/logos/zeiss-logo.png';
import zeissImage from '../media/unternehmen/images/zeiss-img.jpg';
import dlconLogo from '../media/unternehmen/logos/dlcon-logo.png';
import dlconImage from '../media/unternehmen/images/dlcon-img.jpg';
import boehringerLogo from '../media/unternehmen/logos/boehringer-logo.png';
import boehringerImage from '../media/unternehmen/images/boehringer-img.jpg';
import cosmoLogo from '../media/unternehmen/logos/cosmo-logo.png';
import cosmoImage from '../media/unternehmen/images/cosmo-img.jpg';
import dawLogo from '../media/unternehmen/logos/daw-logo.png';
import dawImage from '../media/unternehmen/images/daw-img.jpg';
import dentsplyLogo from '../media/unternehmen/logos/dentsply-logo.png';
import dentsplyImage from '../media/unternehmen/images/dentsply-img.jpg';
import doehlerLogo from '../media/unternehmen/logos/doehler-logo.png';
import doehlerImage from '../media/unternehmen/images/doehler-img.jpg';
import eshelterLogo from '../media/unternehmen/logos/e-shelter-logo.png';
import eshelterImage from '../media/unternehmen/images/e-shelter-img.jpg';
import equensworldlineLogo from '../media/unternehmen/logos/equensworldline-logo.png';
import equensworldlineImage from '../media/unternehmen/images/equensworldline-img.jpg';
import evonikLogo from '../media/unternehmen/logos/evonik-logo.png';
import evonikImage from '../media/unternehmen/images/evonik-img.jpg';
import freseniusLogo from '../media/unternehmen/logos/fresenius-logo.png';
import freseniusImage from '../media/unternehmen/images/fresenius-img.jpg';
import johndeereLogo from '../media/unternehmen/logos/johndeere-logo.png';
import johndeereImage from '../media/unternehmen/images/johndeere-img.jpg';
import ksbLogo from '../media/unternehmen/logos/ksb-logo.png';
import ksbImage from '../media/unternehmen/images/ksb-img.jpg';
import mindsolutionsLogo from '../media/unternehmen/logos/mindsolutions-logo.png';
import mindsolutionsImage from '../media/unternehmen/images/mindsolutions-img.jpg';
import protivitiLogo from '../media/unternehmen/logos/protiviti-logo.png';
import protivitiImage from '../media/unternehmen/images/protiviti-img.jpg';
import roehmLogo from '../media/unternehmen/logos/roehm-logo.png';
import roehmImage from '../media/unternehmen/images/roehm-img.jpg';
import schaefflerLogo from '../media/unternehmen/logos/schaeffler-logo.png';
import schaefflerImage from '../media/unternehmen/images/schaeffler-img.jpg';
import dbschenkerLogo from '../media/unternehmen/logos/dbschenker-logo.png';
import dbschenkerImage from '../media/unternehmen/images/dbschenker-img.jpg';
import bauhausLogo from '../media/unternehmen/logos/bauhaus-logo.png';
import bauhausImage from '../media/unternehmen/images/bauhaus-img.jpg';
import cbsLogo from '../media/unternehmen/logos/cbs-logo.png';
import cbsImage from '../media/unternehmen/images/cbs-img.jpg';
import gizLogo from '../media/unternehmen/logos/giz-logo.png';
import gizImage from '../media/unternehmen/images/giz-img.jpg';
import lindeLogo from '../media/unternehmen/logos/linde-logo.png';
import lindeImage from '../media/unternehmen/images/linde-img.jpg';
import swanLogo from '../media/unternehmen/logos/swan-logo.png';
import swanImage from '../media/unternehmen/images/swan-img.jpg';
import teconnectivityLogo from '../media/unternehmen/logos/teconnectivity-logo.png';
import teconnectivityImage from '../media/unternehmen/images/teconnectivity-img.jpg';
import unileverLogo from '../media/unternehmen/logos/unilever-logo.png';
import unileverImage from '../media/unternehmen/images/unilever-img.jpg';

import petrich from '../media/dozenten/petrich-img.jpg';
import lempart from '../media/dozenten/lempart-img.jpg';
import harms from '../media/dozenten/no-img.jpg';
import tarnow from '../media/dozenten/tarnow-img.jpg';
import noll from '../media/dozenten/noll-img.jpg';
import krug from '../media/dozenten/krug-img.jpg';
import lang from '../media/dozenten/lang-img.jpg';
import holschuh from '../media/dozenten/holschuh-img.jpg';
import mewaldt from '../media/dozenten/mewaldt-img.jpg';
import velasco from '../media/dozenten/velasco-img.jpg';
import altenhofen from '../media/dozenten/altenhofen-img.jpg';
import antonino from '../media/dozenten/antonino-img.jpg';
import bendl from '../media/dozenten/bendl-img.jpg';
import marheine from '../media/dozenten/marheine-img.jpg';
import hoch from '../media/dozenten/hoch-img.jpg';

import froelich from '../media/alumni/froelich-img.jpeg';
import acar from '../media/alumni/acar-img.jpeg';
import moermann from '../media/alumni/moermann-img.jpeg';
import noeldemann from '../media/alumni/noeldemann-img.jpeg';
import schindel from '../media/alumni/schindel-img.jpeg';

// Shuffling cards when loading them into TINBIT
const shuffleArray = (array) => {
  const shuffledArray = array.slice(); // Create a copy of the array
  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }
  return shuffledArray;
};

export const companies = shuffleArray([
  {
    id: 1,
    name: 'BYK-Chemie',
    logo: bykLogo,
    image: bykImage,
    industry: 'Chemie',
    employees: '2.500',
    main_location: 'Wesel',
    description:
      'Wir bei BYK, einem Teil der ALTANA Gruppe, sind ein weltweit führender Anbieter von Spezialchemie mit über 2.500 Mitarbeitern und einem globalen Netz von Niederlassungen. Unser größter Standort ist in Wesel mit rund 1.000 Mitarbeitern und besteht seit 1962. Wir verbessern die Eigenschaften von Materialien wie Kratzfestigkeit, Glanz, mechanische Festigkeit, Fließverhalten, Lichtbeständigkeit und Flammhemmung. Im Rahmen des IMBIT-Studiengangs bieten wir eine praxisorientierte Ausbildung im Bereich IT an. Unsere Studierenden lernen das internationale Tages- und Projektgeschäft kennen und können sich in verschiedenen kaufmännischen Abteilungen umsehen. Sie übernehmen von Anfang an Verantwortung und stellen zweimal im Jahr ihre Arbeitsergebnisse unserem IT-Management vor. Außerdem unterstützen wir ein Auslandssemester an einem ALTANA Standort.',
    applyLink:
      'https://www.byk.com/de/unternehmen-news/karriere/ausbildung/imbit-mwd',
    applyLinkShort: 'byk.de',
  },
  {
    id: 2,
    name: 'PwC',
    logo: pwcLogo,
    image: pwcImage,
    industry: 'Wirtschaftsprüfung',
    employees: '327.000',
    main_location: 'Frankfurt a. Main',
    description:
      'PwC Deutschland, Teil des globalen Netzwerks von PricewaterhouseCoopers, ist eine der führenden Wirtschaftsprüfungs- und Beratungsgesellschaften. Mit über 327.000 Mitarbeitern weltweit bietet PwC umfassende Dienstleistungen in den Bereichen Wirtschaftsprüfung, Steuer- und Rechtsberatung sowie Unternehmens- und Managementberatung. Unser duales Studienprogramm kombiniert theoretisches Wissen mit praktischen Erfahrungen in verschiedenen Geschäftsbereichen, unterstützt von erfahrenen Mentoren und umfangreichen Weiterbildungsmöglichkeiten. Die Studierenden haben die Möglichkeit, an spannenden Projekten mitzuwirken und wertvolle Einblicke in die Arbeit eines globalen Unternehmens zu gewinnen.',
    applyLink: 'https://jobs.pwc.de/de/de/ausbildung',
    applyLinkShort: 'pwc.de',
  },
  {
    id: 3,
    name: 'EY',
    logo: eyLogo,
    image: eyImage,
    industry: 'Wirtschaftsprüfung',
    employees: '365.500',
    main_location: 'Frankfurt a. Main',
    description:
      'Die globale EY-Organisation ist ein marktführendes Unternehmen in den Bereichen Wirtschaftsprüfung, Steuer- und Rechtsberatung sowie Strategy and Transactions und Consulting. Mit unserer Erfahrung und unseren Leistungen stärken wir weltweit das Vertrauen in die Wirtschaft und die Finanzmärkte. Dafür sind wir bestens gerüstet: mit hervorragend ausgebildeten Mitarbeitenden, starken und vielfältigen Teams, exzellenten Leistungen und einem sprichwörtlichen Kundenservice. Unser duales Studienprogramm bietet praxisorientierte Ausbildung und ermöglicht es Studierenden, wertvolle Erfahrungen in verschiedenen Geschäftsbereichen zu sammeln und ihre Karriere in einem internationalen Umfeld zu starten.',
    applyLink:
      'https://www.ey.com/de_de/careers/berufseinstieg-fuer-schueler-innen-ausbildung-studium-bewerbung',
    applyLinkShort: 'ey.com',
  },
  {
    id: 4,
    name: 'Atos',
    logo: atosLogo,
    image: atosImage,
    industry: 'Unternehmensberatung',
    employees: '105.000',
    main_location: 'Frankfurt a. Main',
    description:
      'Atos ist ein weltweit führendes Unternehmen im Bereich der digitalen Transformation. Mit ca. 105.000 Mitarbeitern und einem Jahresumsatz von ca. 11 Milliarden Euro bietet Atos individuelle End-to-End-Lösungen für alle Branchen. Atos kombiniert technologische Expertise mit einem tiefen Verständnis der Branchenanforderungen. Das duale Studienprogramm bei Atos bietet eine praxisorientierte Ausbildung in verschiedenen IT-Bereichen und ermöglicht es Studierenden, wertvolle Einblicke in die digitale Transformation zu gewinnen. Studierende haben die Möglichkeit, an internationalen Projekten mitzuwirken und ihre Fähigkeiten in einem dynamischen Umfeld weiterzuentwickeln.',
    applyLink: 'https://atos.net/de/deutschland/karriere/dualesstudium',
    applyLinkShort: 'atos.de',
  },
  {
    id: 5,
    name: 'Accenture',
    logo: accentureLogo,
    image: accentureImage,
    industry: 'Unternehmensberatung',
    employees: '738.000',
    main_location: 'Frankfurt a. Main',
    description:
      'Accenture ist ein weltweit führendes Beratungsunternehmen, das umfassende Dienstleistungen in den Bereichen Strategie, Consulting, Digital, Technologie und Operations bietet. Mit mehr als 738.000 Mitarbeitern in über 120 Ländern helfen wir unseren Kunden, ihre Leistungsfähigkeit zu steigern und nachhaltige Werte zu schaffen. Unsere dualen Studienprogramme bieten praxisorientierte Ausbildungsmöglichkeiten, die die Studierenden optimal auf eine Karriere in der digitalen Transformation vorbereiten. Sie profitieren von der Zusammenarbeit mit erfahrenen Beratern und der Möglichkeit, an innovativen Projekten mitzuwirken.',
    applyLink: 'https://www.accenture.com/de-de/careers/local/dual-study',
    applyLinkShort: 'accenture.com',
  },
  {
    id: 6,
    name: 'Haribo',
    logo: hariboLogo,
    image: hariboImage,
    industry: 'Süßwaren',
    employees: '7.000',
    main_location: 'Bonn',
    description:
      'HARIBO ist ein weltweit führender Hersteller von Fruchtgummis und Lakritz und in mehr als 120 Ländern vertreten. Mit über 7.000 Mitarbeitern produziert HARIBO an 16 Standorten in zehn Ländern und stellt sicher, dass die Verbraucher stets mit ihren Lieblingsprodukten versorgt werden. Das duale Studienprogramm bei HARIBO bietet praxisnahe Ausbildungsmöglichkeiten in verschiedenen Bereichen der Süßwarenproduktion und -verwaltung. Studierende erhalten Einblicke in die Herstellung und Vermarktung unserer Produkte und können ihre Fähigkeiten in einem dynamischen Umfeld weiterentwickeln.',
    applyLink: 'https://www.haribo.com/de-de/karriere/einstiegsmoeglichkeiten',
    applyLinkShort: 'haribo.com',
  },
  {
    id: 7,
    name: 'Zeiss',
    logo: zeissLogo,
    image: zeissImage,
    industry: 'optische Industrie',
    employees: '38.000',
    main_location: 'Oberkochen',
    description:
      'ZEISS ist ein international führender Technologiekonzern der optischen und optoelektronischen Industrie. Als Unternehmen, das sich vollständig im Besitz einer Stiftung befindet, ist ZEISS in allen seinen Aktivitäten verankert und der Verantwortung verpflichtet. ZEISS fordert die Grenzen der menschlichen Vorstellungskraft heraus und schafft Werte für seine Kunden. Das duale Studienprogramm bei ZEISS bietet umfassende Ausbildungsmöglichkeiten in verschiedenen technischen und kaufmännischen Bereichen. Studierende profitieren von praktischen Erfahrungen und der Möglichkeit, an bedeutenden Innovationsprojekten mitzuwirken.',
    applyLink:
      'https://www.zeiss.de/corporate/karriere/einstieg-bei-zeiss/schuelerinnen-und-schueler/dein-duales-studium-bei-zeiss.html',
    applyLinkShort: 'zeiss.de',
  },
  {
    id: 8,
    name: 'DLCON',
    logo: dlconLogo,
    image: dlconImage,
    industry: 'SAP-Beratung',
    employees: '30',
    main_location: 'Weinheim',
    description:
      'DLCON steigert die Wettbewerbs- und Zukunftsfähigkeit seiner Kunden durch zuverlässige und qualitativ hochwertige Lösungen auf anspruchsvollem technologischen Niveau. Mit einem kleinen, hoch spezialisierten Team bietet DLCON maßgeschneiderte SAP-Beratung und -Implementierung für verschiedene Branchen. Das duale Studienprogramm bei DLCON ermöglicht es den Studierenden, direkt an Projekten mitzuwirken und wertvolle Erfahrungen in der Unternehmenssoftware zu sammeln. Studierende profitieren von einer intensiven Betreuung und der Möglichkeit, ihre Fähigkeiten und ihr Wissen kontinuierlich weiterzuentwickeln.',
    applyLink: 'https://www.dlcon.de/karriere/ausbildung-bei-dlcon',
    applyLinkShort: 'dlcon.de',
  },
  {
    id: 9,
    name: 'Boehringer Ingelheim',
    logo: boehringerLogo,
    image: boehringerImage,
    industry: 'Pharmazie',
    employees: '53.000',
    main_location: 'Ingelheim am Rhein',
    description:
      'Boehringer Ingelheim ist ein weltweit führendes Pharmaunternehmen, das sich auf die Erforschung, Entwicklung, Herstellung und Vermarktung innovativer Arzneimittel für Mensch und Tier spezialisiert hat. Mit über 53.000 Mitarbeitern in 130 Ländern setzt sich Boehringer Ingelheim für die Gesundheit und das Wohlbefinden von Patienten weltweit ein. Das duale Studienprogramm bietet eine hervorragende Gelegenheit, praktische Erfahrungen in der Pharmaindustrie zu sammeln und theoretisches Wissen anzuwenden. Studierende bei Boehringer Ingelheim profitieren von einer praxisnahen Ausbildung und der Möglichkeit, an bedeutenden Forschungs- und Entwicklungsprojekten teilzunehmen.',
    applyLink:
      'https://www.meine-zukunft-bei-boehringer-ingelheim.de/berufsuebersicht/duale-studiengaenge/b-sc-wirtschaftsinformatik-dh-mwd/',
    applyLinkShort: 'boehringer-ingelheim.com',
  },
  {
    id: 10,
    name: 'Cosmo Consult',
    logo: cosmoLogo,
    image: cosmoImage,
    industry: 'IT-Beratung',
    employees: '1.400',
    main_location: 'Hamburg',
    description:
      'Cosmo Consult ist ein weltweit führender Microsoft-Partner für Unternehmenslösungen und Digitalisierung. Mit über 1.400 Mitarbeitern an Standorten in Europa, Asien und Amerika unterstützt Cosmo Consult seine Kunden bei der digitalen Transformation und der Optimierung ihrer Geschäftsprozesse. Unsere Expertise umfasst ERP-, CRM- und Business-Intelligence-Lösungen sowie maßgeschneiderte Softwareentwicklungen. Das duale Studienprogramm bietet praxisnahe Ausbildungsmöglichkeiten in der IT-Beratung und -Implementierung, die Studierenden wertvolle Einblicke in die Arbeit mit modernen Unternehmenslösungen und digitalen Technologien ermöglichen.',
    applyLink:
      'https://de.cosmoconsult.com/ueber-uns/jobsundkarriere/ausbildungpraktikadiplomarbeiten/',
    applyLinkShort: 'cosmoconsult.com',
  },
  {
    id: 11,
    name: 'DAW',
    logo: dawLogo,
    image: dawImage,
    industry: 'Farben und Lacke',
    employees: '5.600',
    main_location: 'Ober-Ramstadt',
    description:
      'DAW ist ein führendes Unternehmen der Farben- und Lackindustrie in Deutschland und Europa. Als Familienunternehmen in fünfter Generation sind wir stolz auf unsere Innovationskraft und unser Engagement für nachhaltige Produkte und Prozesse. Mit rund 5.600 Mitarbeitern an verschiedenen Standorten entwickeln und produzieren wir eine breite Palette an Farben, Lacken und Bautenschutzprodukten. Das duale Studienprogramm bietet eine praxisnahe Ausbildung in verschiedenen Bereichen, von der Produktion über Forschung und Entwicklung bis hin zu Marketing und Vertrieb. Studierende profitieren von einer umfassenden Betreuung und vielfältigen Entwicklungsmöglichkeiten.',
    applyLink: 'https://daw-karriere.de/stellensuche/',
    applyLinkShort: 'daw.de',
  },
  {
    id: 12,
    name: 'Dentsply Sirona',
    logo: dentsplyLogo,
    image: dentsplyImage,
    industry: 'Medizintechnik',
    employees: '16.200',
    main_location: 'Bensheim',
    description:
      'Dentsply Sirona ist der weltweit größte Hersteller von Dentalprodukten und -technologien. Mit über 16.200 Mitarbeitern in mehr als 40 Ländern entwickeln, produzieren und vertreiben wir innovative Lösungen für Zahnärzte und Zahntechniker. Unsere Produkte und Technologien tragen zur Verbesserung der Zahngesundheit und der Lebensqualität von Patienten weltweit bei. Das duale Studienprogramm bietet eine praxisnahe Ausbildung, die Einblicke in die verschiedenen Bereiche der Medizintechnik ermöglicht, von der Forschung und Entwicklung über die Produktion bis hin zum Marketing und Vertrieb. Studierende profitieren von einem dynamischen Arbeitsumfeld und vielfältigen Karrieremöglichkeiten.',
    applyLink:
      'https://career.dentsplysirona.com/de/schueler/duales-studium.html',
    applyLinkShort: 'dentsplysirona.com',
  },
  {
    id: 13,
    name: 'Döhler',
    logo: doehlerLogo,
    image: doehlerImage,
    industry: 'Getränke- & Lebensmittelindustrie',
    employees: '8.000',
    main_location: 'Darmstadt',
    description:
      'Döhler ist ein weltweit führender Hersteller von natürlichen Zutaten, Ingredient Systemen und integrierten Lösungen für die Lebensmittel- und Getränkeindustrie. Mit über 8.000 Mitarbeitern in mehr als 30 Ländern entwickeln wir innovative Produkte und Konzepte, die den aktuellen Markttrends und Verbraucherbedürfnissen entsprechen. Unsere dualen Studienprogramme bieten eine praxisorientierte Ausbildung, die die Studierenden optimal auf eine Karriere in der Lebensmittel- und Getränkeindustrie vorbereitet. Sie erhalten Einblicke in verschiedene Bereiche wie Produktentwicklung, Qualitätsmanagement und Vertrieb und profitieren von umfangreichen Weiterbildungsmöglichkeiten.',
    applyLink:
      'https://jobs.doehler.com/job/Darmstadt-Duales-Studium-Bachelor-of-Science-Internationale-Wirtschaftsinformatik-%28IMBIT%29-ab-01_10_2023-HE-64295/841820501/',
    applyLinkShort: 'doehler.com',
  },
  {
    id: 14,
    name: 'e-shelter security',
    logo: eshelterLogo,
    image: eshelterImage,
    industry: 'Sicherheitstechnik',
    employees: 250,
    main_location: 'Frankfurt am Main',
    description:
      'e-shelter security bietet innovative Sicherheitslösungen und Dienstleistungen für Unternehmen und öffentliche Einrichtungen. Mit einem Team von rund 250 Mitarbeitern entwickeln und implementieren wir maßgeschneiderte Sicherheitskonzepte, die höchsten Standards entsprechen und auf die individuellen Bedürfnisse unserer Kunden zugeschnitten sind. Unsere Expertise umfasst die Bereiche Zutrittskontrolle, Videoüberwachung, Einbruchmeldetechnik und Brandschutz. Das duale Studienprogramm bietet eine praxisnahe Ausbildung, die die Studierenden optimal auf eine Karriere in der Sicherheitstechnik vorbereitet.',
    applyLink: 'https://e-shelter-security.jobs.personio.de/',
    applyLinkShort: 'e-shelter-security.de',
  },
  {
    id: 15,
    name: 'equens Worldline',
    logo: equensworldlineLogo,
    image: equensworldlineImage,
    industry: 'Zahlungssysteme und Transaktionen',
    employees: '18.000',
    main_location: 'Frankfurt am Main',
    description:
      'Worldline ist ein weltweit führender Anbieter von sicheren Zahlungsdienstleistungen und verlässlichen Transaktionen. Mit über 18.000 Mitarbeitern in mehr als 50 Ländern bieten wir umfassende Lösungen für den Zahlungsverkehr, die den höchsten Sicherheitsstandards entsprechen und den Anforderungen des modernen Handels gerecht werden. Unsere dualen Studienprogramme bieten eine praxisorientierte Ausbildung, die die Studierenden optimal auf eine Karriere in der Zahlungsverkehrs- und Transaktionsbranche vorbereitet. Sie erhalten Einblicke in verschiedene Bereiche wie Zahlungsabwicklung, Risikomanagement und IT-Sicherheit und profitieren von umfangreichen Weiterbildungsmöglichkeiten.',
    applyLink:
      'https://de.worldline.com/de/home/blog/2020/july1/start-in-den-job-bei-worldline.html',
    applyLinkShort: 'worldline.com',
  },
  {
    id: 16,
    name: 'Evonik',
    logo: evonikLogo,
    image: evonikImage,
    industry: 'Chemieindustrie',
    employees: '33.000',
    main_location: 'Essen',
    description:
      'Evonik ist ein weltweit führendes Unternehmen der Spezialchemie, das innovative Lösungen für eine Vielzahl von Industrien entwickelt. Mit rund 33.000 Mitarbeitern in über 100 Ländern bieten wir ein breites Portfolio an Produkten und Dienstleistungen, die dazu beitragen, die Lebensqualität zu verbessern und die Ressourcen unserer Erde zu schonen. Unsere dualen Studienprogramme bieten eine praxisnahe Ausbildung in verschiedenen Bereichen der Chemieindustrie, von der Forschung und Entwicklung über die Produktion bis hin zum Marketing und Vertrieb. Studierende profitieren von umfangreichen Weiterbildungsmöglichkeiten und der Möglichkeit, an bedeutenden Innovationsprojekten mitzuwirken.',
    applyLink:
      'https://ausbildung.evonik.de/offer/ausbildung-bachelor-of-science-inte/573141',
    applyLinkShort: 'evonik.de',
  },
  {
    id: 17,
    name: 'Fresenius Technologies',
    logo: freseniusLogo,
    image: freseniusImage,
    industry: 'Gesundheitsdiensleistungen',
    employees: '316.000',
    main_location: 'Bad Homburg',
    description:
      'Fresenius ist ein global tätiger Gesundheitskonzern, der Produkte und Dienstleistungen für Dialyse, Krankenhäuser und ambulante medizinische Versorgung anbietet. Mit rund 316.000 Mitarbeitern weltweit setzen wir uns für die Verbesserung der Lebensqualität von Patienten und die Förderung der Gesundheit ein. Unsere dualen Studienprogramme bieten eine praxisnahe Ausbildung in verschiedenen Bereichen des Gesundheitswesens, von der Medizintechnik über das Krankenhausmanagement bis hin zur Forschung und Entwicklung. Studierende profitieren von umfangreichen Weiterbildungsmöglichkeiten und der Möglichkeit, an bedeutenden Projekten zur Verbesserung der Gesundheitsversorgung mitzuwirken.',
    applyLink:
      'https://karriere.fresenius.de/ihr-einstieg/schuelerinnen/ausbildung-und-duales-studium/bachelor-of-science-international-management-for-business-and-information-technology-mwd#tc_9837b9a7_7739_4cd8_a1d4_1cdbb850ec49_tab1',
    applyLinkShort: 'fresenius.de',
  },
  {
    id: 18,
    name: 'John Deere',
    logo: johndeereLogo,
    image: johndeereImage,
    industry: 'Maschinenbau für Landtechnik',
    employees: '2.780',
    main_location: 'Walldorf',
    description:
      'John Deere ist der weltweit führende Hersteller von Landtechnik und bietet eine breite Palette an Maschinen und Dienstleistungen für die Landwirtschaft, den Gartenbau und die Forstwirtschaft. Mit rund 2.780 Mitarbeitern in Deutschland setzen wir uns für die Entwicklung innovativer und nachhaltiger Lösungen ein, die den Anforderungen der modernen Landwirtschaft gerecht werden. Unsere dualen Studienprogramme bieten eine praxisnahe Ausbildung in verschiedenen Bereichen des Maschinenbaus und der Landtechnik, von der Forschung und Entwicklung über die Produktion bis hin zum Vertrieb und Service. Studierende profitieren von umfangreichen Weiterbildungsmöglichkeiten und der Möglichkeit, an bedeutenden Innovationsprojekten mitzuwirken.',
    applyLink:
      'https://www.deere.de/de/unser-unternehmen/john-deere-karriere/lehrlinge-studenten-uber-praktika/zu-den-dualen-studiengängen/',
    applyLinkShort: 'deere.de',
  },
  {
    id: 19,
    name: 'KSB',
    logo: ksbLogo,
    image: ksbImage,
    industry: 'Industriearmaturen und -pumpen',
    employees: '15.000',
    main_location: 'Frankenthal',
    description:
      'KSB ist einer der weltweit führenden Hersteller von Pumpen, Armaturen und zugehörigen Systemen. Mit rund 15.000 Mitarbeitern in über 100 Ländern bieten wir innovative und zuverlässige Lösungen für eine Vielzahl von Anwendungen in den Bereichen Wasser, Abwasser, Energie, Industrie und Gebäudetechnik. Unsere dualen Studienprogramme bieten eine praxisnahe Ausbildung in verschiedenen technischen und kaufmännischen Bereichen, die die Studierenden optimal auf eine Karriere in der Pumpen- und Armaturenindustrie vorbereiten. Sie profitieren von umfangreichen Weiterbildungsmöglichkeiten und der Möglichkeit, an bedeutenden Projekten mitzuwirken.',
    applyLink:
      'https://www.ksb.com/de-de/unternehmen/karriere/stellenangebote/jobarticle/dh-studium-wirtschaftsinformatik-imbit-bachelor-of-science-fuer-2023-639290',
    applyLinkShort: 'ksb.com',
  },
  {
    id: 20,
    name: 'mind solutions',
    logo: mindsolutionsLogo,
    image: mindsolutionsImage,
    industry: 'SAP-Beratung',
    employees: 'n/a',
    main_location: 'Heidelberg',
    description:
      'Mind Solutions ist ein Spezialist für die Implementierung von SAP-Anwendungen in der Lagerverwaltung. Mit einem Team von erfahrenen Beratern bieten wir maßgeschneiderte Lösungen zur Optimierung der Lager- und Logistikprozesse unserer Kunden. Unsere dualen Studienprogramme bieten eine praxisnahe Ausbildung, die die Studierenden optimal auf eine Karriere in der SAP-Beratung vorbereitet. Sie erhalten Einblicke in verschiedene Bereiche wie Systemintegration, Prozessoptimierung und Projektmanagement und profitieren von umfangreichen Weiterbildungsmöglichkeiten.',
    applyLink:
      'https://www.mindsolutions-it.com/wp-content/uploads/Stellenbeschreibung-SAP-Duales-Studium-IMBIT.pdf',
    applyLinkShort: 'mindsolutions-it.com',
  },
  {
    id: 21,
    name: 'Protiviti',
    logo: protivitiLogo,
    image: protivitiImage,
    industry: 'Unternehmensberatung',
    employees: '5.700',
    main_location: 'Frankfurt am Main',
    description:
      'Protiviti ist eine globale Unternehmensberatung, die sich auf Risikomanagement, Geschäftsprozesse und Technologieberatung spezialisiert hat. Mit rund 5.700 Mitarbeitern in mehr als 20 Ländern unterstützen wir unsere Kunden dabei, Risiken zu identifizieren und zu managen, Geschäftsprozesse zu optimieren und technologische Innovationen zu implementieren. Unsere dualen Studienprogramme bieten eine praxisnahe Ausbildung in verschiedenen Bereichen der Unternehmensberatung, die die Studierenden optimal auf eine Karriere in der Beratung vorbereitet. Sie profitieren von umfangreichen Weiterbildungsmöglichkeiten und der Möglichkeit, an bedeutenden Projekten mitzuwirken.',
    applyLink: 'https://www.protiviti.com/de-de/careers',
    applyLinkShort: 'protiviti.com',
  },
  {
    id: 22,
    name: 'Röhm',
    logo: roehmLogo,
    image: roehmImage,
    industry: 'Chemieindustrie',
    employees: '3.500',
    main_location: 'Darmstadt',
    description:
      'Röhm ist ein führender Anbieter von Methacrylat-Chemie und entwickelt innovative Produkte für eine Vielzahl von Anwendungen, darunter Automobilbau, Elektronik, Medizin und Bauwesen. Mit rund 3.500 Mitarbeitern weltweit setzen wir auf Nachhaltigkeit und technologische Exzellenz. Unsere dualen Studienprogramme bieten eine praxisnahe Ausbildung in verschiedenen Bereichen der Chemieindustrie, die die Studierenden optimal auf eine Karriere in der Branche vorbereiten. Sie erhalten Einblicke in Forschung und Entwicklung, Produktion und Vertrieb und profitieren von umfangreichen Weiterbildungsmöglichkeiten.',
    applyLink: 'https://www.roehm.com/de/ausbildung',
    applyLinkShort: 'roehm.com',
  },
  {
    id: 23,
    name: 'Schaeffler Technologies',
    logo: schaefflerLogo,
    image: schaefflerImage,
    industry: 'Maschinenbauindustrie',
    employees: '83.000',
    main_location: 'Herzogenaurach',
    description:
      'Schaeffler ist ein global tätiger Automobil- und Industriezulieferer, der innovative Lösungen für eine Vielzahl von Anwendungen entwickelt. Mit rund 83.000 Mitarbeitern weltweit setzen wir auf technologische Exzellenz und Nachhaltigkeit. Unsere dualen Studienprogramme bieten eine praxisnahe Ausbildung in verschiedenen technischen und kaufmännischen Bereichen, die die Studierenden optimal auf eine Karriere in der Maschinenbauindustrie vorbereiten. Sie profitieren von umfangreichen Weiterbildungsmöglichkeiten und der Möglichkeit, an bedeutenden Projekten mitzuwirken.',
    applyLink:
      'https://www.schaeffler.de/de/karriere/ihr-einstieg/schueler/b_of_science_internationales_management/',
    applyLinkShort: 'schaeffler.de',
  },
  {
    id: 24,
    name: 'DB Schenker',
    logo: dbschenkerLogo,
    image: dbschenkerImage,
    industry: 'Transport und Logistik',
    employees: '76.000',
    main_location: 'Berlin',
    description:
      'DB Schenker ist ein weltweit führender Anbieter von Logistik- und Supply-Chain-Lösungen. Mit rund 76.000 Mitarbeitern in mehr als 130 Ländern bieten wir umfassende Dienstleistungen in den Bereichen Landverkehr, Luft- und Seefracht, Kontraktlogistik und Supply-Chain-Management. Unsere dualen Studienprogramme bieten eine praxisnahe Ausbildung in verschiedenen Bereichen der Logistik und des Supply-Chain-Managements, die die Studierenden optimal auf eine Karriere in der Logistikbranche vorbereiten. Sie profitieren von umfangreichen Weiterbildungsmöglichkeiten und der Möglichkeit, an bedeutenden Projekten mitzuwirken.',
    applyLink:
      'https://karriere.deutschebahn.com/karriere-de/dein-einstieg/duales-studium',
    applyLinkShort: 'bahn.de',
  },
  {
    id: 25,
    name: 'BAUHAUS',
    logo: bauhausLogo,
    image: bauhausImage,
    industry: 'Einzelhandel',
    employees: '35.000',
    main_location: 'Mannheim',
    description:
      'BAUHAUS ist eine der größten Baumarktketten Europas und bietet eine breite Palette an Produkten für Heimwerker, Gärtner und Bauprofis. Mit mehr als 270 Fachcentren in 19 Ländern und über 35.000 Mitarbeitern steht BAUHAUS für Qualität, Service und Fachberatung. Das duale Studienprogramm bietet eine praxisnahe Ausbildung, die Einblicke in verschiedene Geschäftsbereiche wie Einkauf, Vertrieb, Logistik und Marketing ermöglicht. Studierende profitieren von umfangreichen Weiterbildungsmöglichkeiten und der Möglichkeit, sich in einem dynamischen und innovativen Arbeitsumfeld weiterzuentwickeln.',
    applyLink: 'https://jobs.bauhaus.info',
    applyLinkShort: 'bauhaus.info',
  },
  {
    id: 26,
    name: 'cbs',
    logo: cbsLogo,
    image: cbsImage,
    industry: 'Unternehmensberatung',
    employees: '1300',
    main_location: 'Heidelberg',
    description:
      'cbs Corporate Business Solutions ist ein führendes Beratungsunternehmen für globale Geschäftsprozesslösungen und SAP-Implementierungen. Mit rund 1300 Mitarbeitern weltweit unterstützen wir Unternehmen bei der digitalen Transformation und der Optimierung ihrer Geschäftsprozesse. Unsere dualen Studienprogramme bieten eine praxisnahe Ausbildung in verschiedenen Bereichen der Unternehmensberatung, die die Studierenden optimal auf eine Karriere in der Beratung vorbereitet. Sie profitieren von umfangreichen Weiterbildungsmöglichkeiten und der Möglichkeit, an bedeutenden Projekten mitzuwirken.',
    applyLink:
      'https://www.cbs-consulting.com/karriere-bei-cbs/stellenangebote/',
    applyLinkShort: 'cbs-consulting.com',
  },
  {
    id: 27,
    name: 'GIZ',
    logo: gizLogo,
    image: gizImage,
    industry: 'Entwicklungszusammenarbeit',
    employees: '25.000',
    main_location: 'Bonn',
    description:
      'Die Deutsche Gesellschaft für Internationale Zusammenarbeit (GIZ) ist ein weltweit tätiges Bundesunternehmen der Entwicklungszusammenarbeit. Mit über 25.000 Mitarbeitern unterstützt die GIZ die Bundesregierung bei der Erreichung ihrer entwicklungspolitischen Ziele und leistet einen Beitrag zur nachhaltigen Entwicklung in Partnerländern. Unsere dualen Studienprogramme bieten eine praxisnahe Ausbildung, die die Studierenden optimal auf eine Karriere in der Entwicklungszusammenarbeit vorbereitet. Sie profitieren von umfangreichen Weiterbildungsmöglichkeiten und der Möglichkeit, an bedeutenden Projekten mitzuwirken.',
    applyLink: 'https://www.giz.de/de/html/jobs.html',
    applyLinkShort: 'giz.de',
  },
  {
    id: 28,
    name: 'Linde Gases Division',
    logo: lindeLogo,
    image: lindeImage,
    industry: 'Industriegase',
    employees: '66.000',
    main_location: 'Pullach',
    description:
      'Die Linde Gases Division ist ein führender Anbieter von Industriegasen und technischen Dienstleistungen. Mit rund 66.000 Mitarbeitern weltweit liefert Linde innovative Lösungen für eine Vielzahl von Branchen, darunter Chemie, Metallurgie, Lebensmittelproduktion und Gesundheitswesen. Unsere dualen Studienprogramme bieten eine praxisnahe Ausbildung, die die Studierenden optimal auf eine Karriere in der Industriegasbranche vorbereitet. Sie profitieren von umfangreichen Weiterbildungsmöglichkeiten und der Möglichkeit, an bedeutenden Projekten mitzuwirken.',
    applyLink: 'https://linde.csod.com/ux/ats/careersite/20/home?c=linde',
    applyLinkShort: 'linde.com',
  },
  {
    id: 29,
    name: 'SWAN',
    logo: swanLogo,
    image: swanImage,
    industry: 'SAP-Beratung',
    employees: '150',
    main_location: 'Augsburg',
    description:
      'Die SWAN GmbH ist ein spezialisierter SAP-Partner für Logistiklösungen. Mit einem Team von 150 Mitarbeitern bietet SWAN maßgeschneiderte SAP-Lösungen zur Optimierung der Lagerverwaltung und Logistikprozesse ihrer Kunden. Unsere dualen Studienprogramme bieten eine praxisnahe Ausbildung, die die Studierenden optimal auf eine Karriere in der SAP-Beratung vorbereitet. Sie profitieren von umfangreichen Weiterbildungsmöglichkeiten und der Möglichkeit, an bedeutenden Projekten mitzuwirken.',
    applyLink: 'https://swan.de/ueber-swan/karriere/',
    applyLinkShort: 'swan.de',
  },
  {
    id: 30,
    name: 'TE Connectivity',
    logo: teconnectivityLogo,
    image: teconnectivityImage,
    industry: 'Elektronik',
    employees: '80.000',
    main_location: 'Bensheim',
    description:
      'TE Connectivity ist ein weltweit führender Anbieter von Verbindungstechnologie und Sensorlösungen. Mit rund 80.000 Mitarbeitern in über 50 Ländern entwickelt TE Connectivity innovative Produkte und Lösungen für die Automobil-, Luft- und Raumfahrt-, Telekommunikations- und Elektronikindustrie. Unsere dualen Studienprogramme bieten eine praxisnahe Ausbildung, die die Studierenden optimal auf eine Karriere in der Elektronikindustrie vorbereitet. Sie profitieren von umfangreichen Weiterbildungsmöglichkeiten und der Möglichkeit, an bedeutenden Projekten mitzuwirken.',
    applyLink: 'https://careers.te.com/',
    applyLinkShort: 'te.com',
  },
  {
    id: 31,
    name: 'Unilever',
    logo: unileverLogo,
    image: unileverImage,
    industry: 'Konsumgüter',
    employees: '150.000',
    main_location: 'Hamburg',
    description:
      'Unilever ist eines der weltweit führenden Unternehmen für Konsumgüter und bietet Produkte in den Bereichen Ernährung, Hygiene und Haushaltsprodukte an. Mit über 150.000 Mitarbeitern weltweit setzt sich Unilever für nachhaltiges Wachstum und soziale Verantwortung ein. Unsere dualen Studienprogramme bieten eine praxisnahe Ausbildung, die die Studierenden optimal auf eine Karriere in der Konsumgüterindustrie vorbereitet. Sie profitieren von umfangreichen Weiterbildungsmöglichkeiten und der Möglichkeit, an bedeutenden Projekten mitzuwirken.',
    applyLink: 'https://karriere.unilever.de',
    applyLinkShort: 'unilever.de',
  },
]);

export const lecturers = shuffleArray([
  {
    id: 1,
    name: 'Volkmar Petrich',
    image: petrich,
    courses: 'Bilanzierung und Grundzüge der Jahresabschlussanalyse',
    cv: [
      {
        year: 'seit 2014',
        position: 'Interim Manager, Dozent und gelisteter Business Angel',
      },
      {
        year: '2005-2014',
        position:
          'Vice President Controlling Procurement & Fleet bei Deutsche Post DHL, Bonn',
      },
      {
        year: '2002-2005',
        position:
          'Leiter Finanzen & Controlling Welt bei Linde AG, Bereich Kältetechnik, Köln ',
      },
      {
        year: '1997-2002',
        position:
          'Director Regional Controlling bei BASF Pharma, Ludwigshafen und London ',
      },
    ],
  },
  {
    id: 2,
    name: 'Stephanie Lempart',
    image: lempart,
    courses: 'IT Projektmanagement und Dienstleistungsmanagement',
    cv: [
      {
        year: 'seit 2016',
        position:
          'Innovationsberatung und Studien, Schwerpunkt: Energie und Mobilität',
      },
      {
        year: '2011-2013',
        position:
          'E.ON Vertrieb Deutschland GmbH, München, Projektleitung Innovationsprojekte und Strategie',
      },
      {
        year: '2008-2010',
        position:
          'E.ON Vertrieb Deutschland GmbH, München, Beschaffung und Vertragsmanagement',
      },
      {
        year: '2007-2008',
        position:
          'E.ON Ruhrgas AG, Essen, Strategieberatung Energieversorgungsunternehmen und Stadtwerke',
      },
    ],
  },
  {
    id: 3,
    name: 'Volker Harms',
    image: harms,
    courses: 'Datenbanken I/II',
    cv: [
      {
        year: '30 Jahre',
        position:
          'SAP als Basis-Entwickler mit C/C++ und ABAP, Service Manager Software Delivery und Produkt Eigentümer LM PCA',
      },
    ],
  },
  {
    id: 4,
    name: 'Haley Tarnow',
    image: tarnow,
    courses: 'Business English I/II',
    cv: [
      {
        year: '20 Jahre',
        position: 'Dozentin für Englisch in Mexiko, Italien und Deutschland',
      },
      {
        year: 'seit 2019',
        position: 'Brand Ambassador Genuine Health',
      },
      {
        year: 'seit 2018',
        position: 'Territory Sales Manager, Mondelez',
      },
    ],
  },
  {
    id: 5,
    name: 'Prof. Dr.-Ing. Stefan Noll',
    image: noll,
    courses: 'Web Programmierung, Verteilte Systeme',
    cv: [
      {
        year: 'seit 2011',
        position:
          'Professur „Software Engineering für betriebliche Anwendungen“ an der Hochschule Furtwangen',
      },
      {
        year: 'seit 2000',
        position:
          'Evaluierer und Reviewer für Forschungsanträge und Forschungsprojekte bei der Europäischen Kommission, Brüssel, Belgien',
      },
      {
        year: '1992-2006',
        position:
          "Abteilungsleiter 'Kommunikation und Kooperation' am Fraunhofer-Institut für Graphische Datenverarbeitung (IGD)",
      },
    ],
  },
  {
    id: 6,
    name: 'Simone Krug',
    image: krug,
    courses:
      'Grundlagen der IT, Wissenschaftliches Arbeiten I/II, Human Machine Interaction, Neue Konzepte II',
    cv: [
      {
        year: 'seit 2021',
        position: 'Professorin an der DHBW Mannheim',
      },
      {
        year: '2014-2021',
        position: 'Selbständige Beraterin im Bereich Compliance/GxP',
      },
      {
        year: '2007-2013',
        position:
          'Promotion an der Universität Mannheim mit Lehr- und Forschungsaufenthalten in den USA und Australien',
      },
    ],
  },
  {
    id: 7,
    name: 'Dr. Robert Lang',
    image: lang,
    courses: 'Analysis und Lineare Algebra, Wirtschaftsmathematik, Statistik',
    cv: [
      {
        year: 'seit 2009',
        position: 'Lehraufträge an der DHBW Mannheim',
      },
      {
        year: '30 Jahre',
        position: 'Softwareentwicklung bei der SAP SE, Walldorf',
      },
      {
        year: '6 Jahre',
        position:
          'wissenschaftlicher Mitarbeiter für Mathematik an der Universität Trier',
      },
    ],
  },
  {
    id: 8,
    name: 'Kai Holschuh',
    image: holschuh,
    courses: 'Fundamentals of Business Administration',
    cv: [
      {
        year: 'seit 2013',
        position: 'Professor an der DHBW Mannheim',
      },
      {
        year: '2001-2009',
        position: 'Independent Consultant, z.B. für Credit Suisse',
      },
      {
        year: '1999-2001',
        position: 'Managing Director bei Fonoservices, Barcelona',
      },
    ],
  },
  {
    id: 9,
    name: 'Rainer Mewaldt',
    image: mewaldt,
    courses: 'Cloud Computing',
    cv: [
      {
        year: 'seit 2011',
        position: 'Head of PLM Cloud Services bei Eviden/Atos',
      },
      {
        year: '2008-2011',
        position: 'Globaler Portfolio Manager für PLM bei Siemens SIS',
      },
      {
        year: '2003-2008',
        position:
          'Consulting und Projektleitung von PLM-Projekten bei den Beratungsunternehmen CSC und BearingPoint',
      },
    ],
  },
  {
    id: 10,
    name: 'Dr. Cristos Velasco',
    image: velasco,
    courses:
      'Information Technology Law, International Business Law & International Organizations',
    cv: [
      {
        year: '2020-2024',
        position:
          'Senior Research Fellow und Mitglied des Outreach Committee Board am Zentrum für KI und digitale Politik (CAIDP)',
      },
      {
        year: 'seit 2012',
        position:
          'Externer Dozent Law and Policy in der IMBIT Division der DHBW in Mannheim und Stuttgart',
      },
      {
        year: 'seit 2011',
        position:
          'Unabhängiger Rechtsberater und Ausbilder in den Bereichen Cyberkriminalität, Cybersicherheit und Datenschutz',
      },
      {
        year: 'seit 2008',
        position:
          'Executive Director Protección Datos México (ProtDataMx) und Evidencia Digital.Lat',
      },
    ],
  },
  {
    id: 11,
    name: 'Christoph Altenhofen',
    image: altenhofen,
    courses: 'Geschäftsprozessmanagement',
    cv: [
      {
        year: 'seit 1996',
        position:
          'Leiter IAO-Zentrum Dok.- und Workflow-Management beim Fraunhofer Institut',
      },
    ],
  },
  {
    id: 12,
    name: 'Pablo Oliveira Antonino',
    image: antonino,
    courses: 'Software Lifecycle',
    cv: [
      {
        year: 'seit 2019',
        position: 'Dozent - Computer Science bei TU Kaiserslautern',
      },
      {
        year: 'seit 2017',
        position:
          'Department Head "Virtual Engineering" bei Fraunhofer-Institut für Experimentelles Software Engineering IESE',
      },
      {
        year: '2009-2017',
        position:
          'Engineer/Project Manager bei Fraunhofer-Institut für Experimentelles Software Engineering IESE',
      },
    ],
  },
  {
    id: 13,
    name: 'Prof. Dr. Harald Bendl',
    image: bendl,
    courses:
      'Studiengangsleiter, Supply Chain Management, Projektmanagement, Finanzierung',
    cv: [
      {
        year: 'seit 2011',
        position: 'Professor an der DHBW',
      },
      {
        year: '2007-2010',
        position: 'Madison Advisors Management Consultants, Bukarest, Partner',
      },
      {
        year: '1994-2007',
        position:
          'Horváth & Partners Unternehmensberatung Leitender Berater, Senior Projektmanager, Prokurist',
      },
    ],
  },
  {
    id: 14,
    name: 'Timm Marheine',
    image: marheine,
    courses: 'Einführung in die IT',
    cv: [
      {
        year: 'seit 2009',
        position: 'Geschäftsführer der DLCON AG',
      },
      {
        year: '2017-2021',
        position: 'Vice President IT (Interim) bei Carcoustics',
      },
      {
        year: '2015-2016',
        position: 'IT-Projektmanager bei GAZPROM Germania GmbH',
      },
    ],
  },
  {
    id: 15,
    name: 'Rainer Hoch',
    image: hoch,
    courses: 'Studiengangsleiter',
    cv: [
      {
        year: 'seit 2003',
        position: 'Professor und Studiengangsleiter IMBIT an der DHBW Mannheim',
      },
      {
        year: '1996-2003',
        position: 'Development Architect, Basis Technology Development bei SAP',
      },
      {
        year: '1991-1995',
        position:
          'Wissenschaftler und Projekt Manager, Dokumenten Analyse am DFKI in Kaiserslautern',
      },
      {
        year: '1989-1991',
        position:
          'Wissenschaftler, Elektronisches Publizieren und Dokumentenstandards, GMD-IPSI, Darmstadt (jetzt Fraunhofer Institut)',
      },
    ],
  },
]);

export const alumni = shuffleArray([
  {
    id: 1,
    name: 'Jonathan Frölich',
    image: froelich,
    graduationYear: 2020,
    currentJob: 'Senior Consultant',
    currentCompany: 'BCG Platinion',
    cv: [
      {
        year: 'seit 2024',
        position: 'Senior Consultant bei BCG Platinion',
      },
      {
        year: '2022-2023',
        position: 'IT Consultant bei BCG Platinion',
      },
      {
        year: '2020-2022',
        position: 'Junior Management Consultant bei Atos',
      },
      {
        year: '2018-2020',
        position: 'Dualer Student bei Atos',
      },
      {
        year: '2014-2017',
        position: 'Operations Technischer Assistent TU München',
      },
    ],
  },
  {
    id: 2,
    name: 'Kadir Acar',
    image: acar,
    graduationYear: 2012,
    currentJob: 'Head of Transformation Management',
    currentCompany: 'E.ON Digital Technology',
    cv: [
      {
        year: 'seit 2024',
        position:
          'Head of Transformation Management bei E.ON Digital Technology',
      },
      {
        year: '2020-2024',
        position: 'VP Digital Sales Platform bei E.ON Deutschland',
      },
      {
        year: '2015-2020',
        position: 'Manager bei BCG Platinion',
      },
      {
        year: '2012-2015',
        position: 'Senior Consultant bei IBM',
      },
    ],
  },
  {
    id: 3,
    name: 'Fabio Mörmann',
    image: moermann,
    graduationYear: 2015,
    currentJob: 'VP of Technology',
    currentCompany: 'Digital Science',
    cv: [
      {
        year: 'seit 2024',
        position: 'VP of Technology bei Digital Science',
      },
      {
        year: '2022-2024',
        position:
          'Technical Consultant/Digital Strategy Advisor bei Cloudflight',
      },
      {
        year: '2018-2022',
        position: 'IT Project Lead bei CONWEAVER GmbH',
      },
      {
        year: '2015-2018',
        position: 'Software Developer bei Heidelberg Instruments',
      },
      {
        year: '2012-2015',
        position: 'Dualer Student bei Accenture',
      },
    ],
  },
  {
    id: 4,
    name: 'Pauline Nöldemann',
    image: noeldemann,
    graduationYear: 2019,
    currentJob: 'Geschäftsführerin',
    currentCompany: 'BesserEsser',
    cv: [
      {
        year: 'seit 2023',
        position: 'Geschäftsführerin bei BesserEsser',
      },
      {
        year: '2021-2021',
        position: 'Expert IT Consultant bei Cofinpro AG',
      },
      {
        year: '2019-2020',
        position: 'IT Consultant bei Cofinpro AG',
      },
      {
        year: '2016-2019',
        position: 'Duale Studentin bei BYK-Chemie',
      },
    ],
  },
  {
    id: 5,
    name: 'Ruben Schindel',
    image: schindel,
    graduationYear: 2020,
    currentJob: 'Consultant',
    currentCompany: 'McKinsey & Company',
    cv: [
      {
        year: 'seit 2023',
        position: 'Consultant bei McKinsey & Company',
      },
      {
        year: '2021-2023',
        position:
          'Backend Developer in IBMs Health Platform/Master@IBM: Information Systems Engineering and Management am KIT in Karlsruhe ',
      },
      {
        year: '2017-2020',
        position: 'Dualer Student bei IBM',
      },
    ],
  },
]);
