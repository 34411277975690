// src/components/tinbit/data/data.js

// Barajando cartas al cargarlas en TINBIT
const shuffleArray = (array) => {
  const shuffledArray = array.slice(); // Crear una copia del array
  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }
  return shuffledArray;
};

export const companies = shuffleArray([
  {
    id: 1,
    name: 'BYK-Chemie',
    logo: import('../media/unternehmen/logos/byk-logo.png'),
    image: import('../media/unternehmen/images/byk-img.jpg'),
    industry: 'Química',
    employees: '2.500',
    main_location: 'Wesel',
    description:
      'En BYK, parte del grupo ALTANA, somos un proveedor líder mundial de productos químicos especializados con más de 2.500 empleados y una red global de sucursales. Nuestra mayor ubicación está en Wesel, con alrededor de 1.000 empleados y existe desde 1962. Mejoramos las propiedades de los materiales como la resistencia a los arañazos, el brillo, la resistencia mecánica, el flujo, la resistencia a la luz y la retardación de la llama. Como parte del programa de estudios IMBIT, ofrecemos una formación práctica en el área de TI. Nuestros estudiantes aprenden sobre el negocio diario y de proyectos internacionales y pueden explorar varios departamentos comerciales. Asumen responsabilidades desde el principio y presentan sus resultados de trabajo a nuestro equipo de gestión de TI dos veces al año. Además, apoyamos un semestre en el extranjero en una ubicación de ALTANA.',
    applyLink:
      'https://www.byk.com/de/unternehmen-news/karriere/ausbildung/imbit-mwd',
    applyLinkShort: 'byk.de',
  },
  {
    id: 2,
    name: 'PwC',
    logo: import('../media/unternehmen/logos/pwc-logo.png'),
    image: import('../media/unternehmen/images/pwc-img.jpg'),
    industry: 'Auditoría',
    employees: '327.000',
    main_location: 'Frankfurt a. Main',
    description:
      'PwC Alemania, parte de la red global de PricewaterhouseCoopers, es una de las principales firmas de auditoría y consultoría. Con más de 327.000 empleados en todo el mundo, PwC ofrece servicios integrales en auditoría, consultoría fiscal y legal, así como consultoría empresarial y de gestión. Nuestro programa de estudios dual combina conocimientos teóricos con experiencias prácticas en diversas áreas comerciales, apoyado por mentores experimentados y amplias oportunidades de formación. Los estudiantes tienen la oportunidad de participar en proyectos emocionantes y obtener valiosos conocimientos sobre el trabajo en una empresa global.',
    applyLink: 'https://jobs.pwc.de/de/de/ausbildung',
    applyLinkShort: 'pwc.de',
  },
  {
    id: 3,
    name: 'EY',
    logo: import('../media/unternehmen/logos/ey-logo.png'),
    image: import('../media/unternehmen/images/ey-img.jpg'),
    industry: 'Auditoría',
    employees: '365.500',
    main_location: 'Frankfurt a. Main',
    description:
      'La organización global EY es una empresa líder en los campos de auditoría, consultoría fiscal y legal, Strategy and Transactions y Consultoría. Con nuestra experiencia y servicios, fortalecemos la confianza en la economía y los mercados financieros en todo el mundo. Para ello, estamos bien equipados: con empleados altamente capacitados, equipos fuertes y diversos, servicios excelentes y un servicio al cliente proverbial. Nuestro programa de estudios dual ofrece una formación orientada a la práctica y permite a los estudiantes adquirir experiencias valiosas en diversas áreas comerciales y comenzar su carrera en un entorno internacional.',
    applyLink:
      'https://www.ey.com/de_de/careers/berufseinstieg-fuer-schueler-innen-ausbildung-studium-bewerbung',
    applyLinkShort: 'ey.com',
  },
  {
    id: 4,
    name: 'Atos',
    logo: import('../media/unternehmen/logos/atos-logo.png'),
    image: import('../media/unternehmen/images/atos-img.jpg'),
    industry: 'Consultoría',
    employees: '105.000',
    main_location: 'Frankfurt a. Main',
    description:
      'Atos es una empresa líder mundial en transformación digital. Con aproximadamente 105.000 empleados y una facturación anual de aproximadamente 11 mil millones de euros, Atos ofrece soluciones personalizadas de extremo a extremo para todas las industrias. Atos combina experiencia tecnológica con una comprensión profunda de los requisitos de la industria. El programa de estudios dual en Atos ofrece una formación orientada a la práctica en diversas áreas de TI y permite a los estudiantes obtener valiosos conocimientos sobre la transformación digital. Los estudiantes tienen la oportunidad de participar en proyectos internacionales y desarrollar sus habilidades en un entorno dinámico.',
    applyLink: 'https://atos.net/de/deutschland/karriere/dualesstudium',
    applyLinkShort: 'atos.de',
  },
  {
    id: 5,
    name: 'Accenture',
    logo: import('../media/unternehmen/logos/accenture-logo.png'),
    image: import('../media/unternehmen/images/accenture-img.jpg'),
    industry: 'Consultoría',
    employees: '738.000',
    main_location: 'Frankfurt a. Main',
    description:
      'Accenture es una empresa de consultoría líder a nivel mundial que ofrece servicios integrales en las áreas de estrategia, consultoría, digital, tecnología y operaciones. Con más de 738.000 empleados en más de 120 países, ayudamos a nuestros clientes a mejorar su rendimiento y crear valor sostenible. Nuestros programas de estudios duales ofrecen oportunidades de formación práctica que preparan a los estudiantes de manera óptima para una carrera en la transformación digital. Se benefician de la colaboración con consultores experimentados y la oportunidad de participar en proyectos innovadores.',
    applyLink: 'https://www.accenture.com/de-de/careers/local/dual-study',
    applyLinkShort: 'accenture.com',
  },
  {
    id: 6,
    name: 'Haribo',
    logo: import('../media/unternehmen/logos/haribo-logo.png'),
    image: import('../media/unternehmen/images/haribo-img.jpg'),
    industry: 'Confitería',
    employees: '7.000',
    main_location: 'Bonn',
    description:
      'HARIBO es un fabricante líder mundial de gomitas y regaliz, presente en más de 120 países. Con más de 7.000 empleados, HARIBO produce en 16 ubicaciones en diez países y se asegura de que los consumidores siempre estén provistos de sus productos favoritos. El programa de estudios dual en HARIBO ofrece oportunidades de formación práctica en diversas áreas de la producción y administración de confitería. Los estudiantes obtienen conocimientos sobre la fabricación y comercialización de nuestros productos y pueden desarrollar sus habilidades en un entorno dinámico.',
    applyLink: 'https://www.haribo.com/de-de/karriere/einstiegsmoeglichkeiten',
    applyLinkShort: 'haribo.com',
  },
  {
    id: 7,
    name: 'Zeiss',
    logo: import('../media/unternehmen/logos/zeiss-logo.png'),
    image: import('../media/unternehmen/images/zeiss-img.jpg'),
    industry: 'Industria óptica',
    employees: '38.000',
    main_location: 'Oberkochen',
    description:
      'ZEISS es un grupo tecnológico líder internacional en la industria óptica y optoelectrónica. Como empresa que pertenece completamente a una fundación, ZEISS está anclada en todas sus actividades y comprometida con la responsabilidad. ZEISS desafía los límites de la imaginación humana y crea valor para sus clientes. El programa de estudios dual en ZEISS ofrece oportunidades de formación integral en diversas áreas técnicas y comerciales. Los estudiantes se benefician de experiencias prácticas y de la oportunidad de participar en proyectos de innovación significativos.',
    applyLink:
      'https://www.zeiss.de/corporate/karriere/einstieg-bei-zeiss/schuelerinnen-und-schueler/dein-duales-studium-bei-zeiss.html',
    applyLinkShort: 'zeiss.de',
  },
  {
    id: 8,
    name: 'DLCON',
    logo: import('../media/unternehmen/logos/dlcon-logo.png'),
    image: import('../media/unternehmen/images/dlcon-img.jpg'),
    industry: 'Consultoría SAP',
    employees: '30',
    main_location: 'Weinheim',
    description:
      'DLCON mejora la competitividad y la sostenibilidad de sus clientes a través de soluciones fiables y de alta calidad a un nivel tecnológico exigente. Con un pequeño equipo altamente especializado, DLCON ofrece consultoría e implementación SAP personalizada para diversas industrias. El programa de estudios dual en DLCON permite a los estudiantes participar directamente en proyectos y adquirir valiosos conocimientos en software empresarial. Los estudiantes se benefician de una atención intensiva y de la oportunidad de desarrollar continuamente sus habilidades y conocimientos.',
    applyLink: 'https://www.dlcon.de/karriere/ausbildung-bei-dlcon',
    applyLinkShort: 'dlcon.de',
  },
  {
    id: 9,
    name: 'Boehringer Ingelheim',
    logo: import('../media/unternehmen/logos/boehringer-logo.png'),
    image: import('../media/unternehmen/images/boehringer-img.jpg'),
    industry: 'Farmacéutica',
    employees: '53.000',
    main_location: 'Ingelheim am Rhein',
    description:
      'Boehringer Ingelheim es una empresa farmacéutica líder mundial que se especializa en la investigación, desarrollo, fabricación y comercialización de medicamentos innovadores para humanos y animales. Con más de 53.000 empleados en 130 países, Boehringer Ingelheim se dedica a la salud y el bienestar de los pacientes en todo el mundo. El programa de estudios dual ofrece una excelente oportunidad para adquirir experiencia práctica en la industria farmacéutica y aplicar conocimientos teóricos. Los estudiantes en Boehringer Ingelheim se benefician de una formación práctica y de la oportunidad de participar en proyectos de investigación y desarrollo significativos.',
    applyLink:
      'https://www.meine-zukunft-bei-boehringer-ingelheim.de/berufsuebersicht/duale-studiengaenge/b-sc-wirtschaftsinformatik-dh-mwd/',
    applyLinkShort: 'boehringer-ingelheim.com',
  },
  {
    id: 10,
    name: 'Cosmo Consult',
    logo: import('../media/unternehmen/logos/cosmo-logo.png'),
    image: import('../media/unternehmen/images/cosmo-img.jpg'),
    industry: 'Consultoría IT',
    employees: '1.400',
    main_location: 'Hamburgo',
    description:
      'Cosmo Consult es un socio líder mundial de Microsoft para soluciones empresariales y digitalización. Con más de 1.400 empleados en ubicaciones en Europa, Asia y América, Cosmo Consult apoya a sus clientes en la transformación digital y la optimización de sus procesos comerciales. Nuestra experiencia abarca soluciones de ERP, CRM e inteligencia empresarial, así como desarrollos de software a medida. El programa de estudios dual ofrece oportunidades de formación práctica en consultoría e implementación de IT, permitiendo a los estudiantes obtener valiosos conocimientos sobre el trabajo con soluciones empresariales modernas y tecnologías digitales.',
    applyLink:
      'https://de.cosmoconsult.com/ueber-uns/jobsundkarriere/ausbildungpraktikadiplomarbeiten/',
    applyLinkShort: 'cosmoconsult.com',
  },
  {
    id: 11,
    name: 'DAW',
    logo: import('../media/unternehmen/logos/daw-logo.png'),
    image: import('../media/unternehmen/images/daw-img.jpg'),
    industry: 'Pinturas y Barnices',
    employees: '5.600',
    main_location: 'Ober-Ramstadt',
    description:
      'DAW es una empresa líder en la industria de pinturas y barnices en Alemania y Europa. Como empresa familiar en su quinta generación, estamos orgullosos de nuestra capacidad de innovación y nuestro compromiso con productos y procesos sostenibles. Con alrededor de 5.600 empleados en varias ubicaciones, desarrollamos y producimos una amplia gama de pinturas, barnices y productos de protección de edificios. El programa de estudios dual ofrece una formación práctica en diversas áreas, desde la producción hasta la investigación y desarrollo, pasando por el marketing y las ventas. Los estudiantes se benefician de una atención integral y de diversas oportunidades de desarrollo.',
    applyLink: 'https://daw-karriere.de/stellensuche/',
    applyLinkShort: 'daw.de',
  },
  {
    id: 12,
    name: 'Dentsply Sirona',
    logo: import('../media/unternehmen/logos/dentsply-logo.png'),
    image: import('../media/unternehmen/images/dentsply-img.jpg'),
    industry: 'Tecnología Médica',
    employees: '16.200',
    main_location: 'Bensheim',
    description:
      'Dentsply Sirona es el mayor fabricante mundial de productos y tecnologías dentales. Con más de 16.200 empleados en más de 40 países, desarrollamos, producimos y comercializamos soluciones innovadoras para dentistas y técnicos dentales. Nuestros productos y tecnologías contribuyen a mejorar la salud dental y la calidad de vida de los pacientes en todo el mundo. El programa de estudios dual ofrece una formación práctica que permite a los estudiantes obtener conocimientos sobre las diversas áreas de la tecnología médica, desde la investigación y desarrollo hasta la producción, pasando por el marketing y las ventas. Los estudiantes se benefician de un entorno de trabajo dinámico y de diversas oportunidades de carrera.',
    applyLink:
      'https://career.dentsplysirona.com/de/schueler/duales-studium.html',
    applyLinkShort: 'dentsplysirona.com',
  },
  {
    id: 13,
    name: 'Döhler',
    logo: import('../media/unternehmen/logos/doehler-logo.png'),
    image: import('../media/unternehmen/images/doehler-img.jpg'),
    industry: 'Industria de Alimentos y Bebidas',
    employees: '8.000',
    main_location: 'Darmstadt',
    description:
      'Döhler es un fabricante líder mundial de ingredientes naturales, sistemas de ingredientes y soluciones integradas para la industria de alimentos y bebidas. Con más de 8.000 empleados en más de 30 países, desarrollamos productos y conceptos innovadores que responden a las tendencias actuales del mercado y las necesidades de los consumidores. Nuestros programas de estudios duales ofrecen una formación práctica que prepara a los estudiantes de manera óptima para una carrera en la industria de alimentos y bebidas. Obtienen conocimientos sobre diversas áreas como el desarrollo de productos, la gestión de calidad y las ventas, y se benefician de amplias oportunidades de formación.',
    applyLink:
      'https://jobs.doehler.com/job/Darmstadt-Duales-Studium-Bachelor-of-Science-Internationale-Wirtschaftsinformatik-%28IMBIT%29-ab-01_10_2023-HE-64295/841820501/',
    applyLinkShort: 'doehler.com',
  },
  {
    id: 14,
    name: 'e-shelter security',
    logo: import('../media/unternehmen/logos/e-shelter-logo.png'),
    image: import('../media/unternehmen/images/e-shelter-img.jpg'),
    industry: 'Tecnología de Seguridad',
    employees: 250,
    main_location: 'Frankfurt am Main',
    description:
      'e-shelter security ofrece soluciones y servicios de seguridad innovadores para empresas e instituciones públicas. Con un equipo de aproximadamente 250 empleados, desarrollamos e implementamos conceptos de seguridad personalizados que cumplen con los más altos estándares y están adaptados a las necesidades individuales de nuestros clientes. Nuestra experiencia abarca áreas como control de acceso, videovigilancia, tecnología de alarmas contra intrusos y protección contra incendios. El programa de estudios dual ofrece una formación práctica que prepara a los estudiantes de manera óptima para una carrera en la tecnología de seguridad.',
    applyLink: 'https://e-shelter-security.jobs.personio.de/',
    applyLinkShort: 'e-shelter-security.de',
  },
  {
    id: 15,
    name: 'equens Worldline',
    logo: import('../media/unternehmen/logos/equensworldline-logo.png'),
    image: import('../media/unternehmen/images/equensworldline-img.jpg'),
    industry: 'Sistemas de Pago y Transacciones',
    employees: '18.000',
    main_location: 'Frankfurt am Main',
    description:
      'Worldline es un proveedor líder mundial de servicios de pago seguros y transacciones fiables. Con más de 18.000 empleados en más de 50 países, ofrecemos soluciones integrales para los pagos, que cumplen con los más altos estándares de seguridad y las demandas del comercio moderno. Nuestros programas de estudios duales ofrecen una formación práctica que prepara a los estudiantes de manera óptima para una carrera en la industria de los pagos y transacciones. Obtienen conocimientos sobre diversas áreas como la gestión de pagos, la gestión de riesgos y la seguridad informática, y se benefician de amplias oportunidades de formación.',
    applyLink:
      'https://de.worldline.com/de/home/blog/2020/july1/start-in-den-job-bei-worldline.html',
    applyLinkShort: 'worldline.com',
  },
  {
    id: 16,
    name: 'Evonik',
    logo: import('../media/unternehmen/logos/evonik-logo.png'),
    image: import('../media/unternehmen/images/evonik-img.jpg'),
    industry: 'Industria Química',
    employees: '33.000',
    main_location: 'Essen',
    description:
      'Evonik es una empresa líder mundial en la química especializada, que desarrolla soluciones innovadoras para una variedad de industrias. Con alrededor de 33.000 empleados en más de 100 países, ofrecemos una amplia cartera de productos y servicios que contribuyen a mejorar la calidad de vida y a conservar los recursos de nuestro planeta. Nuestros programas de estudios duales ofrecen una formación práctica en diversas áreas de la industria química, desde la investigación y desarrollo hasta la producción, pasando por el marketing y las ventas. Los estudiantes se benefician de amplias oportunidades de formación y de la oportunidad de participar en proyectos de innovación significativos.',
    applyLink:
      'https://ausbildung.evonik.de/offer/ausbildung-bachelor-of-science-inte/573141',
    applyLinkShort: 'evonik.de',
  },
  {
    id: 17,
    name: 'Fresenius Technologies',
    logo: import('../media/unternehmen/logos/fresenius-logo.png'),
    image: import('../media/unternehmen/images/fresenius-img.jpg'),
    industry: 'Servicios de Salud',
    employees: '316.000',
    main_location: 'Bad Homburg',
    description:
      'Fresenius es un grupo de salud global que ofrece productos y servicios para la diálisis, hospitales y atención médica ambulatoria. Con alrededor de 316.000 empleados en todo el mundo, nos dedicamos a mejorar la calidad de vida de los pacientes y a promover la salud. Nuestros programas de estudios duales ofrecen una formación práctica en diversas áreas del sector de la salud, desde la tecnología médica hasta la gestión hospitalaria, pasando por la investigación y desarrollo. Los estudiantes se benefician de amplias oportunidades de formación y de la oportunidad de participar en proyectos significativos para mejorar la atención médica.',
    applyLink:
      'https://karriere.fresenius.de/ihr-einstieg/schuelerinnen/ausbildung-und-duales-studium/bachelor-of-science-international-management-for-business-and-information-technology-mwd#tc_9837b9a7_7739_4cd8_a1d4_1cdbb850ec49_tab1',
    applyLinkShort: 'fresenius.de',
  },
  {
    id: 18,
    name: 'John Deere',
    logo: import('../media/unternehmen/logos/johndeere-logo.png'),
    image: import('../media/unternehmen/images/johndeere-img.jpg'),
    industry: 'Ingeniería Agrícola',
    employees: '2.780',
    main_location: 'Walldorf',
    description:
      'John Deere es el mayor fabricante mundial de maquinaria agrícola y ofrece una amplia gama de máquinas y servicios para la agricultura, la horticultura y la silvicultura. Con alrededor de 2.780 empleados en Alemania, nos dedicamos al desarrollo de soluciones innovadoras y sostenibles que cumplen con las demandas de la agricultura moderna. Nuestros programas de estudios duales ofrecen una formación práctica en diversas áreas de la ingeniería agrícola, desde la investigación y desarrollo hasta la producción, pasando por las ventas y el servicio. Los estudiantes se benefician de amplias oportunidades de formación y de la oportunidad de participar en proyectos de innovación significativos.',
    applyLink:
      'https://www.deere.de/de/unser-unternehmen/john-deere-karriere/lehrlinge-studenten-uber-praktika/zu-den-dualen-studiengängen/',
    applyLinkShort: 'deere.de',
  },
  {
    id: 19,
    name: 'KSB',
    logo: import('../media/unternehmen/logos/ksb-logo.png'),
    image: import('../media/unternehmen/images/ksb-img.jpg'),
    industry: 'Válvulas y Bombas Industriales',
    employees: '15.000',
    main_location: 'Frankenthal',
    description:
      'KSB es uno de los principales fabricantes mundiales de bombas, válvulas y sistemas asociados. Con alrededor de 15.000 empleados en más de 100 países, ofrecemos soluciones innovadoras y fiables para una variedad de aplicaciones en los sectores del agua, aguas residuales, energía, industria y tecnología de edificios. Nuestros programas de estudios duales ofrecen una formación práctica en diversas áreas técnicas y comerciales, que preparan a los estudiantes de manera óptima para una carrera en la industria de las bombas y válvulas. Se benefician de amplias oportunidades de formación y de la oportunidad de participar en proyectos significativos.',
    applyLink:
      'https://www.ksb.com/de-de/unternehmen/karriere/stellenangebote/jobarticle/dh-studium-wirtschaftsinformatik-imbit-bachelor-of-science-fuer-2023-639290',
    applyLinkShort: 'ksb.com',
  },
  {
    id: 20,
    name: 'mind solutions',
    logo: import('../media/unternehmen/logos/mindsolutions-logo.png'),
    image: import('../media/unternehmen/images/mindsolutions-img.jpg'),
    industry: 'Consultoría SAP',
    employees: 'n/a',
    main_location: 'Heidelberg',
    description:
      'Mind Solutions es un especialista en la implementación de aplicaciones SAP en la gestión de almacenes. Con un equipo de consultores experimentados, ofrecemos soluciones personalizadas para optimizar los procesos de almacenamiento y logística de nuestros clientes. Nuestros programas de estudios duales ofrecen una formación práctica que prepara a los estudiantes de manera óptima para una carrera en la consultoría SAP. Obtienen conocimientos sobre diversas áreas como la integración de sistemas, la optimización de procesos y la gestión de proyectos, y se benefician de amplias oportunidades de formación.',
    applyLink:
      'https://www.mindsolutions-it.com/wp-content/uploads/Stellenbeschreibung-SAP-Duales-Studium-IMBIT.pdf',
    applyLinkShort: 'mindsolutions-it.com',
  },
  {
    id: 21,
    name: 'Protiviti',
    logo: import('../media/unternehmen/logos/protiviti-logo.png'),
    image: import('../media/unternehmen/images/protiviti-img.jpg'),
    industry: 'Consultoría',
    employees: '5.700',
    main_location: 'Frankfurt am Main',
    description:
      'Protiviti es una consultora global especializada en la gestión de riesgos, procesos de negocio y consultoría tecnológica. Con alrededor de 5.700 empleados en más de 20 países, apoyamos a nuestros clientes en la identificación y gestión de riesgos, optimización de procesos de negocio e implementación de innovaciones tecnológicas. Nuestros programas de estudios duales ofrecen una formación práctica en diversas áreas de la consultoría, que preparan a los estudiantes de manera óptima para una carrera en la consultoría. Se benefician de amplias oportunidades de formación y de la oportunidad de participar en proyectos significativos.',
    applyLink: 'https://www.protiviti.com/de-de/careers',
    applyLinkShort: 'protiviti.com',
  },
  {
    id: 22,
    name: 'Röhm',
    logo: import('../media/unternehmen/logos/roehm-logo.png'),
    image: import('../media/unternehmen/images/roehm-img.jpg'),
    industry: 'Industria Química',
    employees: '3.500',
    main_location: 'Darmstadt',
    description:
      'Röhm es un proveedor líder de productos de metacrilato, desarrollando productos innovadores para una variedad de aplicaciones, incluyendo la automoción, la electrónica, la medicina y la construcción. Con alrededor de 3.500 empleados en todo el mundo, nos centramos en la sostenibilidad y la excelencia tecnológica. Nuestros programas de estudios duales ofrecen una formación práctica en diversas áreas de la industria química, que preparan a los estudiantes de manera óptima para una carrera en la industria. Obtienen conocimientos sobre investigación y desarrollo, producción y ventas, y se benefician de amplias oportunidades de formación.',
    applyLink: 'https://www.roehm.com/de/ausbildung',
    applyLinkShort: 'roehm.com',
  },
  {
    id: 23,
    name: 'Schaeffler Technologies',
    logo: import('../media/unternehmen/logos/schaeffler-logo.png'),
    image: import('../media/unternehmen/images/schaeffler-img.jpg'),
    industry: 'Industria de Ingeniería Mecánica',
    employees: '83.000',
    main_location: 'Herzogenaurach',
    description:
      'Schaeffler es un proveedor global de la industria automotriz e industrial, desarrollando soluciones innovadoras para una variedad de aplicaciones. Con alrededor de 83.000 empleados en todo el mundo, nos centramos en la excelencia tecnológica y la sostenibilidad. Nuestros programas de estudios duales ofrecen una formación práctica en diversas áreas técnicas y comerciales, que preparan a los estudiantes de manera óptima para una carrera en la industria de la ingeniería mecánica. Se benefician de amplias oportunidades de formación y de la oportunidad de participar en proyectos significativos.',
    applyLink:
      'https://www.schaeffler.de/de/karriere/ihr-einstieg/schueler/b_of_science_internationales_management/',
    applyLinkShort: 'schaeffler.de',
  },
  {
    id: 24,
    name: 'DB Schenker',
    logo: import('../media/unternehmen/logos/dbschenker-logo.png'),
    image: import('../media/unternehmen/images/dbschenker-img.jpg'),
    industry: 'Transporte y Logística',
    employees: '76.000',
    main_location: 'Berlín',
    description:
      'DB Schenker es un proveedor líder mundial de soluciones logísticas y de cadena de suministro. Con alrededor de 76.000 empleados en más de 130 países, ofrecemos servicios integrales en transporte terrestre, flete aéreo y marítimo, logística contractual y gestión de la cadena de suministro. Nuestros programas de estudios duales ofrecen una formación práctica en diversas áreas de la logística y la gestión de la cadena de suministro, que preparan a los estudiantes de manera óptima para una carrera en la industria logística. Se benefician de amplias oportunidades de formación y de la oportunidad de participar en proyectos significativos.',
    applyLink:
      'https://karriere.deutschebahn.com/karriere-de/dein-einstieg/duales-studium',
    applyLinkShort: 'bahn.de',
  },
  {
    id: 25,
    name: 'BAUHAUS',
    logo: import('../media/unternehmen/logos/bauhaus-logo.png'),
    image: import('../media/unternehmen/images/bauhaus-img.jpg'),
    industry: 'Venta al por menor',
    employees: '35.000',
    main_location: 'Mannheim',
    description:
      'BAUHAUS es una de las mayores cadenas de tiendas de mejoras para el hogar de Europa, ofreciendo una amplia gama de productos para aficionados al bricolaje, jardineros y profesionales de la construcción. Con más de 270 centros especializados en 19 países y más de 35.000 empleados, BAUHAUS representa calidad, servicio y asesoramiento especializado. El programa de estudios dual ofrece una formación práctica que permite a los estudiantes obtener conocimientos sobre diversas áreas comerciales como compras, ventas, logística y marketing. Los estudiantes se benefician de amplias oportunidades de formación y de la oportunidad de desarrollarse en un entorno de trabajo dinámico e innovador.',
    applyLink: 'https://jobs.bauhaus.info',
    applyLinkShort: 'bauhaus.info',
  },
  {
    id: 26,
    name: 'cbs',
    logo: import('../media/unternehmen/logos/cbs-logo.png'),
    image: import('../media/unternehmen/images/cbs-img.jpg'),
    industry: 'Consultoría',
    employees: '1300',
    main_location: 'Heidelberg',
    description:
      'cbs Corporate Business Solutions es una empresa de consultoría líder en soluciones de procesos comerciales globales e implementaciones SAP. Con alrededor de 1300 empleados en todo el mundo, apoyamos a las empresas en la transformación digital y la optimización de sus procesos comerciales. Nuestros programas de estudios duales ofrecen una formación práctica en diversas áreas de la consultoría, que preparan a los estudiantes de manera óptima para una carrera en la consultoría. Se benefician de amplias oportunidades de formación y de la oportunidad de participar en proyectos significativos.',
    applyLink:
      'https://www.cbs-consulting.com/karriere-bei-cbs/stellenangebote/',
    applyLinkShort: 'cbs-consulting.com',
  },
  {
    id: 27,
    name: 'GIZ',
    logo: import('../media/unternehmen/logos/giz-logo.png'),
    image: import('../media/unternehmen/images/giz-img.jpg'),
    industry: 'Cooperación para el Desarrollo',
    employees: '25.000',
    main_location: 'Bonn',
    description:
      'La Deutsche Gesellschaft für Internationale Zusammenarbeit (GIZ) es una empresa federal de cooperación para el desarrollo con operaciones globales. Con más de 25.000 empleados, GIZ apoya al gobierno alemán en el logro de sus objetivos de política de desarrollo y contribuye al desarrollo sostenible en los países socios. Nuestros programas de estudios duales ofrecen una formación práctica que prepara a los estudiantes de manera óptima para una carrera en la cooperación para el desarrollo. Se benefician de amplias oportunidades de formación y de la oportunidad de participar en proyectos significativos.',
    applyLink: 'https://www.giz.de/de/html/jobs.html',
    applyLinkShort: 'giz.de',
  },
  {
    id: 28,
    name: 'Linde Gases Division',
    logo: import('../media/unternehmen/logos/linde-logo.png'),
    image: import('../media/unternehmen/images/linde-img.jpg'),
    industry: 'Gases Industriales',
    employees: '66.000',
    main_location: 'Pullach',
    description:
      'La División de Gases de Linde es un proveedor líder de gases industriales y servicios técnicos. Con alrededor de 66.000 empleados en todo el mundo, Linde ofrece soluciones innovadoras para una variedad de industrias, incluyendo la química, la metalurgia, la producción de alimentos y la atención médica. Nuestros programas de estudios duales ofrecen una formación práctica que prepara a los estudiantes de manera óptima para una carrera en la industria de los gases industriales. Se benefician de amplias oportunidades de formación y de la oportunidad de participar en proyectos significativos.',
    applyLink: 'https://linde.csod.com/ux/ats/careersite/20/home?c=linde',
    applyLinkShort: 'linde.com',
  },
  {
    id: 29,
    name: 'SWAN',
    logo: import('../media/unternehmen/logos/swan-logo.png'),
    image: import('../media/unternehmen/images/swan-img.jpg'),
    industry: 'Consultoría SAP',
    employees: '150',
    main_location: 'Augsburg',
    description:
      'SWAN GmbH es un socio SAP especializado en soluciones logísticas. Con un equipo de 150 empleados, SWAN ofrece soluciones SAP personalizadas para optimizar la gestión de almacenes y los procesos logísticos de sus clientes. Nuestros programas de estudios duales ofrecen una formación práctica que prepara a los estudiantes de manera óptima para una carrera en la consultoría SAP. Se benefician de amplias oportunidades de formación y de la oportunidad de participar en proyectos significativos.',
    applyLink: 'https://swan.de/ueber-swan/karriere/',
    applyLinkShort: 'swan.de',
  },
  {
    id: 30,
    name: 'TE Connectivity',
    logo: import('../media/unternehmen/logos/teconnectivity-logo.png'),
    image: import('../media/unternehmen/images/teconnectivity-img.jpg'),
    industry: 'Electrónica',
    employees: '80.000',
    main_location: 'Bensheim',
    description:
      'TE Connectivity es un proveedor líder mundial de tecnología de conexión y soluciones de sensores. Con alrededor de 80.000 empleados en más de 50 países, TE Connectivity desarrolla productos y soluciones innovadoras para las industrias automotriz, aeroespacial, de telecomunicaciones y electrónica. Nuestros programas de estudios duales ofrecen una formación práctica que prepara a los estudiantes de manera óptima para una carrera en la industria electrónica. Se benefician de amplias oportunidades de formación y de la oportunidad de participar en proyectos significativos.',
    applyLink: 'https://careers.te.com/',
    applyLinkShort: 'te.com',
  },
  {
    id: 31,
    name: 'Unilever',
    logo: import('../media/unternehmen/logos/unilever-logo.png'),
    image: import('../media/unternehmen/images/unilever-img.jpg'),
    industry: 'Bienes de Consumo',
    employees: '150.000',
    main_location: 'Hamburgo',
    description:
      'Unilever es una de las principales empresas de bienes de consumo del mundo, que ofrece productos en los campos de la nutrición, la higiene y los productos del hogar. Con más de 150.000 empleados en todo el mundo, Unilever se compromete con el crecimiento sostenible y la responsabilidad social. Nuestros programas de estudios duales ofrecen una formación práctica que prepara a los estudiantes de manera óptima para una carrera en la industria de bienes de consumo. Se benefician de amplias oportunidades de formación y de la oportunidad de participar en proyectos significativos.',
    applyLink: 'https://karriere.unilever.de',
    applyLinkShort: 'unilever.de',
  },
]);

export const lecturers = shuffleArray([
  {
    id: 1,
    name: 'Volkmar Petrich',
    image: import('../media/dozenten/petrich-img.jpg'),
    courses: 'Contabilidad y Análisis de Balance',
    cv: [
      {
        year: 'desde 2014',
        position: 'Interim Manager, Docente y Business Angel listado',
      },
      {
        year: '2005-2014',
        position:
          'Vicepresidente de Control de Compras y Flota en Deutsche Post DHL, Bonn',
      },
      {
        year: '2002-2005',
        position:
          'Jefe de Finanzas y Control Mundial en Linde AG, División de Tecnología de Refrigeración, Colonia',
      },
      {
        year: '1997-2002',
        position:
          'Director de Control Regional en BASF Pharma, Ludwigshafen y Londres',
      },
    ],
  },
  {
    id: 2,
    name: 'Stephanie Lempart',
    image: import('../media/dozenten/lempart-img.jpg'),
    courses: 'Gestión de Proyectos IT y Gestión de Servicios',
    cv: [
      {
        year: 'desde 2016',
        position:
          'Consultoría en Innovación y Estudios, Enfoque: Energía y Movilidad',
      },
      {
        year: '2011-2013',
        position:
          'E.ON Vertrieb Deutschland GmbH, Múnich, Gestión de Proyectos de Innovación y Estrategia',
      },
      {
        year: '2008-2010',
        position:
          'E.ON Vertrieb Deutschland GmbH, Múnich, Adquisiciones y Gestión de Contratos',
      },
      {
        year: '2007-2008',
        position:
          'E.ON Ruhrgas AG, Essen, Consultoría Estratégica para Empresas de Servicios Públicos y Municipales',
      },
    ],
  },
  {
    id: 3,
    name: 'Volker Harms',
    image: import('../media/dozenten/no-img.jpg'),
    courses: 'Bases de Datos I/II',
    cv: [
      {
        year: '30 yeare',
        position:
          'Desarrollador Base en SAP con C/C++ y ABAP, Gerente de Servicios de Entrega de Software y Propietario de Producto LM PCA',
      },
    ],
  },
  {
    id: 4,
    name: 'Haley Tarnow',
    image: import('../media/dozenten/tarnow-img.jpg'),
    courses: 'Inglés de Negocios I/II',
    cv: [
      {
        year: '20 yeare',
        position: 'Docente de Inglés en México, Italia y Alemania',
      },
      {
        year: 'desde 2019',
        position: 'Embajadora de Marca Genuine Health',
      },
      {
        year: 'desde 2018',
        position: 'Gerente de Ventas de Territorio, Mondelez',
      },
    ],
  },
  {
    id: 5,
    name: 'Prof. Dr.-Ing. Stefan Noll',
    image: import('../media/dozenten/noll-img.jpg'),
    courses: 'Programación Web, Sistemas Distribuidos',
    cv: [
      {
        year: 'desde 2011',
        position:
          'Profesor de "Ingeniería de Software para Aplicaciones Empresariales" en Hochschule Furtwangen',
      },
      {
        year: 'desde 2000',
        position:
          'Evaluador y Revisor de Propuestas de Investigación y Proyectos de Investigación en la Comisión Europea, Bruselas, Bélgica',
      },
      {
        year: '1992-2006',
        position:
          'Jefe de Departamento de "Comunicación y Cooperación" en el Instituto Fraunhofer de Procesamiento de Datos Gráficos (IGD)',
      },
    ],
  },
  {
    id: 6,
    name: 'Simone Krug',
    image: import('../media/dozenten/krug-img.jpg'),
    courses:
      'Fundamentos de IT, Trabajo Científico I/II, Interacción Hombre-Máquina, Nuevos Conceptos II',
    cv: [
      {
        year: 'desde 2021',
        position: 'Profesora en la DHBW Mannheim',
      },
      {
        year: '2014-2021',
        position: 'Consultora Independiente en el Área de Cumplimiento/GxP',
      },
      {
        year: '2007-2013',
        position:
          'Doctorado en la Universidad de Mannheim con estancias de investigación y docencia en EE.UU. y Australia',
      },
    ],
  },
  {
    id: 7,
    name: 'Dr. Robert Lang',
    image: import('../media/dozenten/lang-img.jpg'),
    courses:
      'Análisis y Álgebra Lineal, Matemáticas Empresariales, Estadística',
    cv: [
      {
        year: 'desde 2009',
        position: 'Docente en la DHBW Mannheim',
      },
      {
        year: '30 yeare',
        position: 'Desarrollo de Software en SAP SE, Walldorf',
      },
      {
        year: '6 yeare',
        position: 'Investigador en Matemáticas en la Universidad de Trier',
      },
    ],
  },
  {
    id: 8,
    name: 'Kai Holschuh',
    image: import('../media/dozenten/holschuh-img.jpg'),
    courses: 'Fundamentos de Administración de Empresas',
    cv: [
      {
        year: 'desde 2013',
        position: 'Profesor en la DHBW Mannheim',
      },
      {
        year: '2001-2009',
        position: 'Consultor Independiente, p.ej., para Credit Suisse',
      },
      {
        year: '1999-2001',
        position: 'Director General en Fonoservices, Barcelona',
      },
    ],
  },
  {
    id: 9,
    name: 'Rainer Mewaldt',
    image: import('../media/dozenten/mewaldt-img.jpg'),
    courses: 'Computación en la Nube',
    cv: [
      {
        year: 'desde 2011',
        position: 'Jefe de Servicios en la Nube de PLM en Eviden/Atos',
      },
      {
        year: '2008-2011',
        position: 'Gerente Global de Portafolio para PLM en Siemens SIS',
      },
      {
        year: '2003-2008',
        position:
          'Consultoría y Gestión de Proyectos de PLM en las Consultoras CSC y BearingPoint',
      },
    ],
  },
  {
    id: 10,
    name: 'Dr. Cristos Velasco',
    image: import('../media/dozenten/velasco-img.jpg'),
    courses:
      'Derecho de Tecnología de la Información, Derecho Internacional Empresarial y Organizaciones Internacionales',
    cv: [
      {
        year: '2020-2024',
        position:
          'Investigador Senior y Miembro del Comité de Alcance en el Centro de IA y Política Digital (CAIDP)',
      },
      {
        year: 'desde 2012',
        position:
          'Docente Externo en Derecho y Política en la División IMBIT de la DHBW en Mannheim y Stuttgart',
      },
      {
        year: 'desde 2011',
        position:
          'Consultor Jurídico Independiente y Formador en las Áreas de Ciberdelincuencia, Ciberseguridad y Protección de Datos',
      },
      {
        year: 'desde 2008',
        position:
          'Director Ejecutivo de Protección de Datos México (ProtDataMx) y Evidencia Digital.Lat',
      },
    ],
  },
  {
    id: 11,
    name: 'Christoph Altenhofen',
    image: import('../media/dozenten/altenhofen-img.jpg'),
    courses: 'Gestión de Procesos Empresariales',
    cv: [
      {
        year: 'desde 1996',
        position:
          'Director del Centro de Gestión de Documentos y Flujos de Trabajo en el Instituto Fraunhofer',
      },
    ],
  },
  {
    id: 12,
    name: 'Pablo Oliveira Antonino',
    image: import('../media/dozenten/antonino-img.jpg'),
    courses: 'Ciclo de Vida del Software',
    cv: [
      {
        year: 'desde 2019',
        position: 'Docente de Informática en TU Kaiserslautern',
      },
      {
        year: 'desde 2017',
        position:
          'Jefe de Departamento de "Ingeniería Virtual" en el Instituto Fraunhofer de Ingeniería de Software Experimental IESE',
      },
      {
        year: '2009-2017',
        position:
          'Ingeniero/Gerente de Proyectos en el Instituto Fraunhofer de Ingeniería de Software Experimental IESE',
      },
    ],
  },
  {
    id: 13,
    name: 'Prof. Dr. Harald Bendl',
    image: import('../media/dozenten/bendl-img.jpg'),
    courses:
      'Director del Programa, Gestión de la Cadena de Suministro, Gestión de Proyectos, Finanzas',
    cv: [
      {
        year: 'desde 2011',
        position: 'Profesor en la DHBW',
      },
      {
        year: '2007-2010',
        position: 'Consultor de Gestión de Madison Advisors, Bucarest, Socio',
      },
      {
        year: '1994-2007',
        position:
          'Consultor Principal, Gerente de Proyectos Senior, Procurador en Horváth & Partners Consultoría Empresarial',
      },
    ],
  },
  {
    id: 14,
    name: 'Timm Marheine',
    image: import('../media/dozenten/marheine-img.jpg'),
    courses: 'Introducción a la IT',
    cv: [
      {
        year: 'desde 2009',
        position: 'Director General de DLCON AG',
      },
      {
        year: '2017-2021',
        position: 'Vicepresidente de IT (Interino) en Carcoustics',
      },
      {
        year: '2015-2016',
        position: 'Gerente de Proyectos IT en GAZPROM Germania GmbH',
      },
    ],
  },
  {
    id: 15,
    name: 'Rainer Hoch',
    image: import('../media/dozenten/no-img.jpg'),
    courses: 'Director del Programa de Estudios',
    cv: [
      {
        year: 'desde 2003',
        position: 'Profesor y Director del Programa IMBIT en la DHBW Mannheim',
      },
      {
        year: '1996-2003',
        position:
          'Arquitecto de Desarrollo en SAP, Desarrollo de Tecnología Base',
      },
      {
        year: '1991-1995',
        position:
          'Científico y Gerente de Proyectos, Análisis de Documentos en el DFKI en Kaiserslautern',
      },
      {
        year: '1989-1991',
        position:
          'Científico, Publicación Electrónica y Estándares de Documentos, GMD-IPSI, Darmstadt (ahora Instituto Fraunhofer)',
      },
    ],
  },
]);

export const alumni = shuffleArray([
  {
    id: 1,
    name: 'Jonathan Frölich',
    image: import('../media/alumni/froelich-img.jpeg'),
    graduationYear: 2020,
    currentJob: 'Consultor Senior',
    currentCompany: 'BCG Platinion',
    cv: [
      {
        year: 'desde 2024',
        position: 'Consultor Senior en BCG Platinion',
      },
      {
        year: '2022-2023',
        position: 'Consultor IT en BCG Platinion',
      },
      {
        year: '2020-2022',
        position: 'Consultor de Gestión Junior en Atos',
      },
      {
        year: '2018-2020',
        position: 'Estudiante Dual en Atos',
      },
      {
        year: '2014-2017',
        position: 'Asistente Técnico de Operaciones en la TU München',
      },
    ],
  },
  {
    id: 2,
    name: 'Kadir Acar',
    image: import('../media/alumni/acar-img.jpeg'),
    graduationYear: 2012,
    currentJob: 'Jefe de Gestión de Transformación',
    currentCompany: 'E.ON Digital Technology',
    cv: [
      {
        year: 'desde 2024',
        position:
          'Jefe de Gestión de Transformación en E.ON Digital Technology',
      },
      {
        year: '2020-2024',
        position: 'VP de Plataforma de Ventas Digitales en E.ON Deutschland',
      },
      {
        year: '2015-2020',
        position: 'Gerente en BCG Platinion',
      },
      {
        year: '2012-2015',
        position: 'Consultor Senior en IBM',
      },
    ],
  },
  {
    id: 3,
    name: 'Fabio Mörmann',
    image: import('../media/alumni/moermann-img.jpeg'),
    graduationYear: 2015,
    currentJob: 'VP de Tecnología',
    currentCompany: 'Digital Science',
    cv: [
      {
        year: 'desde 2024',
        position: 'VP de Tecnología en Digital Science',
      },
      {
        year: '2022-2024',
        position:
          'Consultor Técnico/Asesor de Estrategia Digital en Cloudflight',
      },
      {
        year: '2018-2022',
        position: 'Líder de Proyectos IT en CONWEAVER GmbH',
      },
      {
        year: '2015-2018',
        position: 'Desarrollador de Software en Heidelberg Instruments',
      },
      {
        year: '2012-2015',
        position: 'Estudiante Dual en Accenture',
      },
    ],
  },
]);
